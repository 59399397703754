import * as React from 'react';
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IRootState} from "../redux";

import startStyles from './start.module.css';

import steuern2021Path from "../app/steuern2021/config/steuern2021Paths.json";
import musicPath from "../app/music/config/musicPaths.json";
import fotobookPaths from "../app/fotobook/config/fotobookPaths.json";
import sightseeingPath from "../app/sightseeing/config/sightseeingPaths.json";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import { USER_LOGIN, USER_LOGOFF} from "../redux";


const mapStateToProps = (state: IRootState) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    userLogin: (user: any) => {
        dispatch(USER_LOGIN(null))
    } ,
    userLogoff: () => {
        dispatch(USER_LOGOFF())
    }
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

class AppContentPortalComponent extends React.Component<Props, {}> {
    
    private navigateTo = (where: string): void => {
        window.open(where,"_self");
    }
    
    private handleLogin = () => {
        this.props.userLogin(null);
    }
    private handleLogoff = () => {
        this.props.userLogoff();
    }
    
    render() {
        const user = this.props.user;
        return (
            <>
                <div className={startStyles.divTileBlock}>
                    <Link to={steuern2021Path.steuern2021Home}>
                        <div className={startStyles.appPortalTile}>
                            <h3>Steuern 2021 Gartenumbau</h3>
                            <img src={require("./../app/steuern2021/img/steuern2021_64.png")} alt={"Steuern 2021"} />
                        </div>
                    </Link>
                    <Link to={musicPath.musicHome}>
                        <div className={startStyles.appPortalTile}>
                            <h3>Music Video Player</h3>
                            <img src={require("./../app/music/img/music_64px.png")} alt={"Music Video Player"} />
                        </div>
                    </Link>
                    <Link to={fotobookPaths.fotobookHome}>
                        <div className={startStyles.appPortalTile}>
                            <h3>Fotobook</h3>
                            <img src={require("./../app/fotobook/img/fotobookLogo_64.png")} alt={"Fotobook"} />
                        </div>
                    </Link>
                    <Link to={sightseeingPath.sightseeingHome}>
                        <div className={startStyles.appPortalTile}>
                            <h3>Sightseeing</h3>
                            <img src={require("../app/sightseeing/img/sightseeingLogo_64px.jpg")} alt={"Sightseeing"} />
                        </div>
                    </Link>
                </div>
{/*
                UserName: {user.userName}
                <Button onClick={this.handleLogin}>Login</Button> &nbsp; &nbsp; &nbsp;
                <Button onClick={this.handleLogoff}>Log-Off</Button>
*/}
            </>
        )
    }
    
}

// export default connector(AppContentPortal);
export const AppContentPortal = connect(mapStateToProps, mapDispatchToProps)(AppContentPortalComponent);
