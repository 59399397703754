import * as React from 'react';
import {Tab, Tabs} from "react-bootstrap";

import appStyles from "../css/app.module.css";

import dataCH from "./../config/dataCH.json";
import dataD from "./../config/dataD.json";
import dataFra from "./../config/dataFra.json";
import dataIta from "./../config/dataIta.json";
import SightseeingDataContainer from "../pages/dataTopic/SightseeingDataContainer";
import {IDataItems, IDataItem} from "../typeDef/dataData";

const allData: any[] = [dataCH, dataD, dataFra, dataIta];


export  class AppContentSightseeing extends React.Component<{}, {}> {
    
    render() {
        console.log("AppContentSightseeing.render()");
        console.log("allData: ");
        console.log(allData);
        return (
            <div className={appStyles.divApp}>
                <Tabs defaultActiveKey={allData[0].dataKey} id="topNavSteuern2021" variant={'tabs'} fill={false} >
                    {allData.map((dataItem, dataIdx) => {
                        return (
                            <Tab eventKey={dataItem.dataKey} title={dataItem.dataLabel} key={"tabKey_" + dataIdx} tabClassName={appStyles.topNavigation}>
                                <br />
                                <SightseeingDataContainer dataItem={dataItem} />
                            </Tab>
                        )
                    })}
                </Tabs>
            </div>
        )
    }
    
}