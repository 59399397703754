import React from 'react';

import clubStyles from "./club.module.css";
import {getlocalHtdocsPath} from "../../config/getConfigData";

import {IClubItem, IClubItems} from "../../typeDef/clubData";

interface IProps {
    clubDataList?: IClubItems;
    fnSelectClub: (c: IClubItem) => void;
}

export class ClubDynListing extends React.Component<IProps, {}> {

    openUrlInNewTab = (evt: any, url: string) => {
        evt.stopPropagation();
        const newWindow = window.open(url, 'ClubLink', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    render() {
        const { clubDataList } = this.props;
        const logoDir = getlocalHtdocsPath();
        return (
            <>

{/*
                -- List all Clubs ------------------------------------------------------------------------------------
*/}

                <div className={"newLine"}>
                    <br/>
                </div>

                <ul key={"clsb_ul"} className={clubStyles.clubGirlListUl}>
                    {clubDataList.map((clubItem, clubIdx) => {
                        const logoFile = logoDir + "club/club_" + clubItem.clubId + "/logo-" + clubItem.clubTechRef + ".png";
                            return (
                                <li key={"li_"+ clubIdx} onClick={() => this.props.fnSelectClub(clubItem)}>
                                    <div className={clubStyles.clubTitleDiv}>
                                        {clubItem.clubName}
                                    </div>
                                    <div key={"li_div_1_" + clubIdx} className={clubStyles.clubListDiv}>
                                        <img src={logoFile} className={clubStyles.clubLogoGeneral} alt={clubItem.clubName}  />
                                    </div>
                                    <div className={clubStyles.badgeInfo}>
                                        {clubItem.clubType}
                                    </div>
                                    <div className={clubStyles.badgeGray} onClick={(e) => this.openUrlInNewTab(e, clubItem.url)}>
{/*
                                        <a href={clubItem.url} target={"ClubLink"}><img src={"/img/symbol/url_web_icon.png"} width={"16px"} height={"16px"} /></a>
*/}
                                        <img src={require("../../img/weblink_48px.png")} width={"32px"} height={"32px"} title={"Open Website of " + clubItem.clubName} />
                                    </div>
                                    <br/><br/>
                                </li>

                            )
                        }

                    )}



                </ul>

            </>
        )
    }
}
