import React from 'react';
import inputStyles from "./input.module.css";

interface IProps {
    name: string;
    isEditable: boolean;
    value: number;
    width: string;
    fnChangeHandler: (action: string, name: string, value: string) => void;
}
export class InputNumber extends React.Component<IProps, {}> {

    onChangeHandler = (evt: any) => {
        const { name, isEditable, fnChangeHandler } = this.props;

        if (isEditable) {
            fnChangeHandler('change', name, evt.target.value);
        }
    }

    render() {
        const { value, name, isEditable } = this.props;
        const width = this.props.width || "100%";
        let showValue: string = "" + value;
        if (value === undefined) {
            showValue = "";
        }
        const styleInputTextInput = (isEditable ? inputStyles.InputTextInputEdit : inputStyles.InputTextInputView);
        return (
            <input className={styleInputTextInput} name={name} style={{width: width}} type="number" value={showValue} onChange={(evt) => this.onChangeHandler(evt)} />
        )
    }
}
