import {Dispatch} from "redux";
import {APPDATA_SETDATAFULL, APPDATA_UPDATEDATAITEM, APPDATA_ADDDATAITEM} from "./action";

export const appDataMapDispatchToProps = (dispatch: Dispatch) => ({
    setDataFull: (entity: string, data: any) => {
        try {
            dispatch(APPDATA_SETDATAFULL(entity, data))
        }
        catch (e) {
            console.log("1st dispatch call raised error");
            console.log(e);
            console.log("try 2nd dispatch");
            dispatch(APPDATA_SETDATAFULL(entity, data))
        }
    },
    updateDataItem: (entity: string, data: any, idName: string) => {
        try {
            dispatch(APPDATA_UPDATEDATAITEM(entity, data, idName))
        }
        catch (e) {
            console.log("1st dispatch call raised error");
            console.log(e);
            console.log("try 2nd dispatch");
            dispatch(APPDATA_UPDATEDATAITEM(entity, data, idName))
        }
    },
    addDataItem: (entity: string, data: any, idName: string) => {
        try {
            dispatch(APPDATA_ADDDATAITEM(entity, data, idName))
        }
        catch (e) {
            console.log("1st dispatch call raised error");
            console.log(e);
            console.log("try 2nd dispatch");
            dispatch(APPDATA_ADDDATAITEM(entity, data, idName))
        }
    }
})