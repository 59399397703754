import React from 'react';

const emptyStar = require("../../img/empty_star_16.png");
const filledStar = require("../../img/filled_star_16.png");

const nineArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

interface IProps {
    value: number;
    name: string;
    isEditable: boolean;
    fnChangeHandler?: (action: string, name: string, value: any) => void;
}

export class NineRating extends React.Component<IProps, {}> {

    private updateNineRating = (newValue: number): void => {
        const {isEditable, name, fnChangeHandler} = this.props;
        
        if (isEditable === true) {
            fnChangeHandler("change", name, newValue);
        }
        
    }

    render() {
        const { value } = this.props;
        return (
            <>
                {nineArray.map((loopNr) => {
                    const loopImage = loopNr <= value ? filledStar : emptyStar;
                    const leftMargin = loopNr === 4 || loopNr === 7 ? 15 : 0;
                    return (
                        <img key={"nineRating_" + loopNr} src={loopImage} alt={"loopNr"} style={{marginLeft: leftMargin + "px"}} onClick={() => this.updateNineRating(loopNr)} />
                    )
                })}
                &nbsp; &nbsp; &nbsp; value: {value}
            </>
        )
    }
}
