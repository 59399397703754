import * as React from 'react';
import {Button, Modal, Form} from "react-bootstrap";

import styles from "../../css/music.module.css";
import {IArtistItem, IArtistItems} from "../../typeDef/artistData";
import {IPlayListItem, IPlayListItems} from "../../typeDef/playList";
import {ISongItem} from "../../typeDef/songData";

interface IProps {
    artistData: IArtistItems;
    fnModalClose: () => void;
}

interface IState {
    dummy: string;
}

export class ArtistDataExport extends React.Component<IProps, IState> {
    
    public state = {
        dummy: "",
    }
    
    handleClose = () => {
        this.props.fnModalClose();
    }
    
    
    render() {
        const {artistData} = this.props;
        const show = true;
        let playListNum: number = 0;
        const allMamaFav: IPlayListItems = [];
        
        artistData.forEach((art: IArtistItem) => {
            art.songList.forEach((song: ISongItem) => {
                if (song.isMamaFav === 1) {
                    playListNum++;
                    const newPlayListItem: IPlayListItem = {
                        num: playListNum,
                        artistId: art.artistId,
                        artistName: art.artistName,
                        fileName: song.fileName,
                        originYear: song.originYear,
                        songDuration: song.songDuration,
                        songId: song.songId,
                        songTitle: song.songTitle,
                    }
                    allMamaFav.push(newPlayListItem);
                }
            })
        })
        const exportData: any = {
            artistData: artistData,
            allMamaFav: allMamaFav,
        }
        const JsonString: string = JSON.stringify(exportData);
        
        
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered={true} dialogClassName={styles.modalWindow} >
                    <Modal.Header closeButton className={styles.modalHeader}>
                        <Modal.Title>Artist Data Export</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={styles.modalBody}>
                        <div>
                            <div className="form-group mb-3">
                                <br />
    
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Artist JSON</Form.Label>
                                        <Form.Control as="textarea" rows={10} readOnly={true} value={JsonString} />
                                    </Form.Group>
                                </Form>
                            </div>
                            
                        </div>
    
                        <div className={styles.divNormal}>
                            <br />
                            <div className="d-grid">
                                <Button type="submit" className="" onClick={null}>Image Upload</Button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        )
    }
    
}