import React from 'react';
import {Table} from "react-bootstrap";

import {ITopicItems, ITopicItem} from "../../typeDef/topicData";

interface IProps {
    topicDataList?: ITopicItems;
    fnSelectTopic: (c: ITopicItem) => void;
}

export class TopicListing extends React.Component<IProps, {}> {
    
    render() {
        const { topicDataList, fnSelectTopic } = this.props;
        
        const topicList: ITopicItems = topicDataList ? topicDataList : [];
        
        return (
            <>
                
                {/*
                -- List all Topics ------------------------------------------------------------------------------------
*/}
                
                <div className={"newLine"}>
                    <br/>
                </div>
                
                <Table width={"100%"}>
                    <colgroup>
                        <col width={"40%"} />
                        <col width={"10%"} />
                        <col width={"20%"} />
                        <col width={"10%"} />
                        <col width={"20%"} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>
                            Topic Subject
                        </th>
                        <th>
                            Country
                        </th>
                        <th>
                            Location
                        </th>
                        <th>
                            # Songs
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {topicList.map((topic: ITopicItem, artIdx) => {
                        return (
                            <tr key={"artTable_TR_" + artIdx} onClick={() => fnSelectTopic(topic)}>
                                <td>
                                    {topic.topicSubject}
                                </td>
                                <td>
                                    {topic.topicCountry}
                                </td>
                                <td>
                                    {topic.topicLocation}
                                </td>
                                <td>
                                    {topic.topicDateFrom} - {topic.topicDateTo}
                                </td>
                                <td />
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                
            
            </>
        )
    }
}
