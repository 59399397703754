import React from 'react';
import {Button, Modal, Nav, Form, Row, Col} from "react-bootstrap";
import sharedStyles from "../../css/shared.module.css";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sharedServices from "../../config/sharedServices.json";
import {getNewId} from "../../../../sharedLib/getIdLib";
import {IRootState} from "../../../../redux";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
//import PropTypes from 'prop-types';

interface ICompProps {
    fnOnClose: () => void;
}

interface IState {
    pwType: string;
    userName: string;
    userPw: string;
}

const mapStateToProps = (state: IRootState) => {
    return {
        zdbUser: state.appData.zdbUser
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type AllProps = DispatchProps & StateProps & ICompProps;

export class LoginFormComponent extends React.Component<AllProps, IState> {
    
    public state = {
        pwType: "password",
        userName: "",
        userPw: "",
    }
    
    private setPasswordType = (pwType: string): void => {
        console.log("setPasswordType: " + pwType);
        this.setState({pwType});
    }
    
    private callbackSubmitLogin = (sc: ServiceCall): void => {
        if (sc.getRequestConfigStatusCode() === 0) {
            const scData = sc.getResponseData();
            this.props.setDataFull("currentUser", [scData]);
            this.props.fnOnClose();
        }
    }
    
    private submitLogin = (): void => {
        const {userName, userPw} = this.state;
        let isAllOk: boolean = true;
        if (!userName || userName === "") {
            isAllOk = false;
            alert("Please enter the user login name");
        }
        if (!userPw || userPw === "") {
            isAllOk = false;
            alert("Please enter the user password");
        }
        if (isAllOk === true) {
            const sc = new ServiceCall(sharedServices.userLogin);
            sc.executePost({userName, userPw}, this.callbackSubmitLogin);
        }
    }
    
    private callbackCreateNewUser = (sc: ServiceCall): void => {
        const scData = sc.getResponseData();
    }
    private createNewUser = (): void => {
        const {userName, userPw} = this.state;
        let isAllOk: boolean = true;
        if (!userName || userName === "") {
            isAllOk = false;
            alert("Please enter the user login name");
        }
        if (!userPw || userPw === "") {
            isAllOk = false;
            alert("Please enter the user password");
        }
        if (isAllOk === true) {
            const sc = new ServiceCall(sharedServices.createNewUser);
            sc.executePost({userName, userPw, userPk: getNewId()}, this.callbackCreateNewUser);
            
        }
        
    }
    
    private onChangeHandler = (evt: any): void => {
        const newState: IState = Object.assign({}, this.state);
        const name = evt.target.name;
        const value = evt.target.value;
        // @ts-ignore
        newState[name] = value;
        this.setState(newState);
    }
    
    render() {
        
        const { pwType, userName, userPw } = this.state;
        const { fnOnClose } = this.props;
        
        console.log("LoginForm.render()");
        
        return (
            <>
                <Modal show={true} onHide={fnOnClose} centered={true} dialogClassName={sharedStyles.modalWindowHalf} >
                    <Modal.Header closeButton className={sharedStyles.modalHeader}>
                        <Modal.Title>User Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={sharedStyles.modalBody}>
                        <div>
                            <Form>
                                <Form.Group as={Row} className="mb-5 mt-5" controlId="formPlaintextEmail">
                                    <Form.Label column sm="4">
                                        User Login Name
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" placeholder="User Login Name" name={"userName"} value={userName} onChange={this.onChangeHandler} />
                                    </Col>
                                </Form.Group>
        
                                <Form.Group as={Row} className="mb-5" controlId="formPlaintextPassword">
                                    <Form.Label column sm="4">
                                        Password
                                    </Form.Label>
                                    <Col sm="7">
                                        <Form.Control type={pwType} placeholder="Password" name={"userPw"} value={userPw} onChange={this.onChangeHandler} />
                                    </Col>
                                    <Col sm="1">
                                        <img src={require("../../../../img/eyePreview_32px.png")} alt={"Preview"} onMouseEnter={() => this.setPasswordType("text")} onMouseLeave={() => this.setPasswordType("password")} />
                                    </Col>
                                </Form.Group>
                                <Button variant="primary" type="button" className={"me-3"} onClick={this.submitLogin}>
                                    Login
                                </Button>
                                <Button variant="secondary" type="reset" className={"me-3"} onClick={fnOnClose}>
                                    Cancel
                                </Button>
                                <Button variant="secondary" type="button" className={"me-3"} onClick={this.createNewUser}>
                                    Create User
                                </Button>

                            </Form>
                            
                        </div>
        
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export const LoginForm = connect(mapStateToProps, appDataMapDispatchToProps)(LoginFormComponent);
