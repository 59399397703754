import React from 'react';
import PropTypes from 'prop-types';

import styles from "./message.module.css";

export class MessageList extends React.Component {
    static propTypes = {
        msgList: PropTypes.arrayOf(PropTypes.object),
    }

    render() {
        const { msgList } = this.props;
        if (msgList) {
            return (
                <>
                    {msgList.length > 0 &&
                        <>
                            <table width={"100%"}>
                                <tbody>
                                {msgList.map(msg => {
                                    let stylesTableMessage = styles.tableMessageWarning;
                                    if (msg.msgType === "error") {
                                        stylesTableMessage = styles.tableMessageError;
                                    }

                                    return (
                                        <tr key={"msg_" + msg.msgNo} className={stylesTableMessage}>
                                            <td>{msg.msgType}</td>
                                            <td>{msg.msgText}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        <br />
                        </>
                    }
                </>
            )
        } else {
            return null;
        }
    }
}
