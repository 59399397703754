const initialUser = {
    userName: "",
    isLoggedIn: false,
    loginSessionId: "",
    loginSessionCred: "",
}
const userReducer = (state = initialUser, action: any) => {
    switch (action.type) {
        case "USER_LOGIN":
            return {
                ...state, isLoggedIn: true, userName: "whatever", loginSessionId: "tbd", loginSessionCred: "tbd"
            }
        case "USER_LOGOFF":
            return {
                ...state, isLoggedIn: false, userName: "", loginSessionId: "", loginSessionCred: ""
            }
        default:
            return state;
    }
}

export default userReducer;