import React from 'react';
//import PropTypes from 'prop-types';

import {IDataItem, ISectionItem, ISectionItems} from "../../typeDef/dataData";
import {Card, Col, Row} from "react-bootstrap";
import appStyles from "../../../fotobook/css/app.module.css";
import genericCssStyles from "../../../../css/appGeneric.module.css";

interface IProps {
    dataItem: IDataItem;
    fnSelectSection: (c: ISectionItem) => void;
}

const fotoPrefix = "/foto/";


export default class SectionOverview extends React.Component<IProps, {}> {
    
    
    
    render() {
        const {dataItem, fnSelectSection} = this.props;
        
        const sectionList: ISectionItems = dataItem && dataItem.sectionList ? dataItem.sectionList : [];
        
        console.log("SectionOverview.render()");
        console.log("dataItem:");
        console.log(dataItem);
        
        return (
            <>
                
                <table className={genericCssStyles.divTable100}>
                    <tbody>
                    <tr>
                        <td>Description:</td>
                        <td>{dataItem.dataDescription}</td>
                    </tr>
                    <tr>
                        <td>Link:</td>
                        <td>
                            <a href={dataItem.dataLink} target={"_blank"}>{dataItem.dataLinkLabel}</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <Row xs={6} md={6} xl={6} className="g-4">
    
                    {sectionList.map((sectionItem: ISectionItem) => {
    
                        let sectionImg = sectionItem && sectionItem.fotoList && sectionItem.fotoList.length > 0 ? fotoPrefix + sectionItem.fotoList[0].fileName : "";
                        console.log("sectionImg: " + sectionImg);
                        
                        return (
                            <React.Fragment key={"sectionItemBlock_" + dataItem.dataKey + "_" + sectionItem.sectionNr}>
                                <Col>
                                    <Card onClick={(e) => fnSelectSection(sectionItem)}>
                                        <Card.Body>
                                            <Card.Title>
                                                {sectionItem.sectionTitle}
                                            </Card.Title>
                                        </Card.Body>
                                        <Card.Img variant="top" src={sectionImg}
                                                  className={appStyles.imgFoto}/>
                                    </Card>
                                </Col>
    
    
                            </React.Fragment>
                        )
                    })}
                    
                </Row>
            
            
            </>
        )
    }
}

