import React from 'react';
//import PropTypes from 'prop-types';

import {IDataItem, ISectionItem} from "../../typeDef/dataData";
import SectionOverview from "./SectionOverview";

import genericCssStyles from "../../../../css/appGeneric.module.css";
import SectionDetails from "./SectionDetails";

interface IState {
    selectedSection: ISectionItem;
}
interface IProps {
    dataItem: IDataItem;
}

const emptySection: ISectionItem = null;
const returnIcon = require("../../../../img/return_24px.png");


export default class SightseeingDataContainer extends React.Component<IProps, IState> {

    public state = {
        selectedSection: emptySection,
    }

    private selectSection = (section: ISectionItem) => {
        this.setState({selectedSection: section});
    }
    
    render() {
        const { selectedSection } = this.state;
        const { dataItem } = this.props;
        
        
        let action = "sectionList";
        if (selectedSection) {
            action = "sectionDetails";
        }
        
        console.log("SightseeingDataContainer.render()");
        console.log("dataItem:");
        console.log(dataItem);
    
        return (
            <>
{/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectSection(null)}>
                        {dataItem.dataLabel}
                    </div>
                    {selectedSection &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"}>
                                {selectedSection.sectionTitle}
                                <img src={returnIcon} alt={"return"} style={{paddingLeft: "10px"}} onClick={() => this.selectSection(null)} />
                            </div>
                        </>
                    }
                </div>
                
                <div className={genericCssStyles.divTable100}>
                    <br />
    
                    {action === "sectionList" &&
                        <SectionOverview dataItem={dataItem} fnSelectSection={this.selectSection} />
                    }
                    {action === "sectionDetails" &&
                        <SectionDetails sectionItem={selectedSection} />
                    }
                    
                </div>
            
                
            </>
        )
    }
}

