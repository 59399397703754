export const actionCommands = {
    user: {
            login: "USER_LOGIN",
            logoff: "USER_LOGOFF",
    },
    appData: {
        setDataFull: "APPDATA_SETDATAFULL",
        addDataItem: "APPDATA_ADDDATAITEM",
        updateDataItem: "APPDATA_UPDATEDATAITEM",
        deleteDataItem: "APPDATA_DELETEDATAITEM",
    }
}
