import React from 'react';
import inputStyles from "./input.module.css";

interface IProps {
    name: string;
    isEditable: boolean;
    value: string;
    width?: string;
    fnChangeHandler: (action: string, name: string, value: string) => void;
    password?: boolean;
}

export class InputText extends React.Component<IProps, {}> {

    onChangeHandler = (evt: any) => {
        const { name, isEditable, fnChangeHandler } = this.props;

        if (isEditable) {
            fnChangeHandler('change', name, evt.target.value);
        }
    }

    render() {
        const { value, name, isEditable } = this.props;
        const width = this.props.width || "100%";
        const password = this.props.password || false;
        const styleInputTextInput = (isEditable ? inputStyles.InputTextInputEdit : inputStyles.InputTextInputView);
        const showValue = value ? value : ""; // set showValue to "" instead null;
        const inputType = password === true ? "password" : "text";
        return (
                    <input className={styleInputTextInput} name={name} style={{width: width}} type={inputType} value={showValue} onChange={(evt) => this.onChangeHandler(evt)} />
        )
    }
}
