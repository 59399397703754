import * as React from 'react';
import {Tab, Tabs} from "react-bootstrap";

import appStyles from "../css/app.module.css";

import dataVorab from "../config/topicVorher.json";
import dataUmbau from "../config/topicUmbau.json";
import dataNachher from "../config/topicNachher.json";
import {TopicPage} from "./TopicPage";

const allData = [dataVorab, dataUmbau, dataNachher];

export  class AppContentSteuern2021 extends React.Component<{}, {}> {
    
    render() {
        return (
            <div className={appStyles.divApp}>
                <Tabs defaultActiveKey={allData[0].topicKey} id="topNavSteuern2021" variant={'tabs'} fill={false} >
                    {allData.map((dataItem, dataIdx) => {
                        return (
                            <Tab eventKey={dataItem.topicKey} title={dataItem.topicLabel} key={"tabKey_" + dataIdx} tabClassName={appStyles.topNavigation}>
                                <br />
                                <TopicPage topicItem={dataItem} />
                            </Tab>
                        )
                    })}
                </Tabs>
            </div>
        )
    }
    
}