import React from 'react';

import musicStyles from "../../css/music.module.css";
import {IArtistItem} from "../../typeDef/artistData";
import {InputText} from "../../../../component/input/InputText";
import {InputTextarea} from "../../../../component/input/InputTextarea";

interface IProps {
    showArtist: IArtistItem;
    fnChangeHandler: (action: string, name?: string, newValue?: any) => void;
}

export class ArtistDetailsArtistBlock extends React.Component<IProps, {}> {
    
    
    render() {
        const { showArtist, fnChangeHandler } = this.props;
        
        const artistDir = "http://localhost/htdocs/music/artist/";
        
        return (
            <>
                <table className={"App-TableBlock"} width={"100%"}>
                    <colgroup>
                        <col width={"20%"} />
                        <col width={"40%"} />
                        <col width={"40%"} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td colSpan={2}>
                            <h4>Artist</h4>
                        </td>
                        <td rowSpan={4}>
                            <img src={artistDir + "artist_" + showArtist.artistId + "/foto.png"} alt={showArtist.artistName} className={musicStyles.artistPhotoImg} />
                        </td>
                    </tr>
                    <tr>
                        <th className={"App-TableBlockCellLabel"}>
                            Name:
                        </th>
                        <td className={"App-TableBlockCellField"}>
                            <InputText name={"artistName"} isEditable={false}
                                       width={"calc(100% - 20px)"} value={showArtist.artistName}
                                       fnChangeHandler={fnChangeHandler}/>
                        </td>
                    </tr>
                    <tr>
                        <th className={"App-TableBlockCellLabel"}>
                            Type:
                        </th>
                        <td className={"App-TableBlockCellField"}>
                            <InputText name={"artistType"} isEditable={false}
                                       width={"calc(50% - 20px)"} value={showArtist.artistType}
                                       fnChangeHandler={fnChangeHandler}/>
                        </td>
                    </tr>
                    <tr>
                        <th className={"App-TableBlockCellLabel"}>
                            Description:
                        </th>
                        <td className={"App-TableBlockCellField"}>
                            <InputTextarea name={"artistDesc"} isEditable={false}
                                           width={"calc(100% - 20px)"}
                                           value={showArtist.artistDesc}
                                           fnChangeHandler={fnChangeHandler} rows={4}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </>
        )
    }
}
