import React from 'react';
import inputStyles from "./input.module.css";

interface IProps {
    name: string;
    isEditable: boolean;
    value?: any;
    width?: string;
    rows?: number;
    fnChangeHandler: (action: string, name: string, value: any) => void;
}

export class InputTextarea extends React.Component<IProps, {}> {


    onChangeHandler = (evt: any) => {
        const { name, isEditable, fnChangeHandler } = this.props;

        if (isEditable) {
            fnChangeHandler('change', name, evt.target.value);
        }
    }

    render() {
        const { value, name, isEditable } = this.props;
        const width = this.props.width || "100%";
        const rows = this.props.rows || 5;
        const styleInputTextInput = (isEditable ? inputStyles.InputTextInputEdit : inputStyles.InputTextInputView);
        let showValue = value;
        if (showValue === null || showValue === undefined) {
            showValue = "";
        }
        return (
            <textarea className={styleInputTextInput} name={name} rows={rows} style={{width: width}} value={showValue} onChange={(evt) => this.onChangeHandler(evt)} />
        )
    }
}
