import {IGirlItem} from "../../typeDef/girlData";
import {convertDateToDateString} from "../../../../sharedLib/dateLib";

const newGirl: IGirlItem = {
    age: null,
    girlId: 1,
    girlName: "",
    girlExtention: "",
    numHadSex: 0,
    hair: "",
    height: null,
    intimity: "",
    lang: "",
    nat: "",
    serviceInfo: "",
    tits: "",
    weight: null,
    wsMode: "A",
    girlInfo: "",
    girlTattoo: "",
    firstActivationDate: convertDateToDateString(new Date()),
    lastInactivationDate: null,
    ratingBody: null,
    ratingFace: null,
    ratingServices: null,
    ratingComment: "",
    girlClubList: [],
    fileItems: [],
    clubId: null
}

export function getNewGirlObject (clubId: number) {
    const newGirlObject = Object.assign({}, newGirl);
    newGirlObject.clubId = clubId;
    return newGirlObject;
}
