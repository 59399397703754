import React from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {IMessageList} from "../../../../typeDef/messageList";
import {MessageList} from "../../../../component/messageList/MessageList";
import slfServicePath from "../../config/slfServices.json";
import {IRootState} from "../../../../redux";
import {connect} from "react-redux";
import { appDataMapDispatchToProps } from "../../../../redux/appDataMapDispatchToProps";
import {TopicListing} from "./TopicListing";
import {ITopicItem} from "../../typeDef/topicData";
import {TopicPartListing} from "./TopicPartListing";
import {Alert, Button} from "react-bootstrap";

interface IApiFeedback {
    state: string;
    statusCode: number;
    error: string;
    msgList?: IMessageList
}

interface IState {
    apiFeedback: IApiFeedback;
    selectedTopicId: number;
}


const mapStateToProps = (state: IRootState) => {
    return {
        adSlfFotobookTopic: state.appData.slfFotobookTopic,
        adSlfFotobookTopicPart: state.appData.slfFotobookTopicPart,
        currentUser: state.appData.currentUser
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

const emptyMsgList: IMessageList = [];
const emptyApiFeedback: IApiFeedback = null;

class TopicComponent extends React.Component<Props, IState> {

    public state = {
        apiFeedback: emptyApiFeedback,
        selectedTopicId: 0,
    }

    componentDidMount = () => {
        this.loadAllTopicListing();
    }
    
    private callbackLoadAllTopic = (sc: ServiceCall) => {
        const apiFeedback: IApiFeedback = {
            state: "loaded",
            error: sc.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            statusCode: sc.getRequestConfigStatusCode(),
            msgList: sc.getResponseInfo(),
        }
        this.props.setDataFull("slfFotobookTopic", sc.getResponseData());
        this.setState({apiFeedback});
    }
    private loadAllTopicListing = () => {
        const {currentUser} = this.props;
        const securityToken = currentUser && currentUser.length > 0 ? currentUser[0].securityToken : {};
        const cs = new ServiceCall(slfServicePath.allTopicListing);
        cs.executePost({securityToken}, this.callbackLoadAllTopic);
    }
    
    private callbackLoadAllTopicPart = (sc: ServiceCall) => {
        const apiFeedback: IApiFeedback = {
            state: "loaded",
            error: sc.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            statusCode: sc.getRequestConfigStatusCode(),
            msgList: sc.getResponseInfo(),
        }
        this.props.setDataFull("slfFotobookTopicPart", sc.getResponseData());
        this.setState({apiFeedback});
    }
    private loadAllTopicPartListingPerTopic = (id: number) => {
        const cs = new ServiceCall(slfServicePath.topicPartListingPerTopic);
        cs.executePost({id}, this.callbackLoadAllTopicPart);
    }
    
    
    private selectTopic = (topic: ITopicItem) => {
        if (topic) {
            this.setState({selectedTopicId: topic.fotobookTopicId});
            this.loadAllTopicPartListingPerTopic(topic.fotobookTopicId);
        } else {
            this.setState({selectedTopicId: 0});
        }
    }
    
    render() {
        const { apiFeedback, selectedTopicId } = this.state;
        const { adSlfFotobookTopic, adSlfFotobookTopicPart, currentUser } = this.props;
        
        
        let action = "topicList";
        const selectedTopic: ITopicItem = selectedTopicId && selectedTopicId > 0 ? adSlfFotobookTopic.find((topic: ITopicItem) => topic.fotobookTopicId === selectedTopicId) : null;
        
        if (selectedTopic) {
            action = "topicPartList";
        }
    
        return (
            <>
{/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectTopic(null)}>
                        all Topics
                    </div>
                    {selectedTopic &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"} onClick={() => this.selectTopic(selectedTopic)}>
                                {selectedTopic.topicSubject}
                            </div>
                        </>
                    }
                </div>
    
                {apiFeedback && apiFeedback.statusCode === 3 &&
                    <div className={"mt-3"}>
                        <Alert variant={"warning"}>
                            You need to be logged in to have access to that part of application
                            {currentUser && currentUser.length > 0 &&
                                <Button onClick={this.loadAllTopicListing} className={"m-md-2"}>Retry</Button>
                            }
                        </Alert>
                        
                    </div>
                }
                {apiFeedback && apiFeedback.msgList &&
                    <MessageList msgList={apiFeedback.msgList} />
                }
    
                {action === "topicList" &&
                    <TopicListing fnSelectTopic={this.selectTopic} topicDataList={adSlfFotobookTopic}/>
                }
                {action === "topicPartList" &&
                    <TopicPartListing topicPartDataList={adSlfFotobookTopicPart} isEditable={selectedTopic.topicIsEditable} />
                }
                
                
            </>
        )
    }
}

export const TopicContainer = connect(mapStateToProps, appDataMapDispatchToProps)(TopicComponent);
