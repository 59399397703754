import * as React from 'react';
import ReactPlayer from 'react-player';

import appStyles from "../css/app.module.css";
import {BigFotoNavigator} from "./BigFotoNavigator";


interface IFotoItem {
    fileName?: string;
    fotoDescription?: string;
}
interface IFotoItems extends Array<IFotoItem>{}

interface IProps {
    topicItem: any;
}

interface IState {
    showBigFotoNavigator: boolean;
    fotoList?: IFotoItems;
    selectedFotoIdx: number;
}

export  class TopicPage extends React.Component<IProps, IState> {
    
    public state = {
        showBigFotoNavigator: false,
        fotoList: [] as IFotoItems,
        selectedFotoIdx: 0,
    }
    
    openBigImageNavigator = (e: any, fotoList: IFotoItems, fotoIdx: number) => {
        e.preventDefault();
        console.log("openBigImageNavigator: " + fotoIdx);
        console.log(fotoList);
        this.setState({showBigFotoNavigator: true, fotoList, selectedFotoIdx: fotoIdx});
    }
    
    closeBigFotoNavigator = () => {
        this.setState({showBigFotoNavigator: false, fotoList: [], selectedFotoIdx: null});
    }
    
    render() {
        const { topicItem } = this.props;
        const { showBigFotoNavigator, fotoList, selectedFotoIdx } = this.state;
        
        console.log("TopicPage.render():");
        console.log(topicItem);
        
        return (
            <>
                <div className={"divTopic"}>
                    <div>
                        <u>Beschreibung</u><br />
                        {topicItem.topicDescription}
                    </div>
                    <br />
                    {topicItem.sectionList.map((sectionItem: any, sectionIdx: number) => {
                        const localFotoList: IFotoItems = [];
                        sectionItem.fotoList.forEach((foto: IFotoItem, fotoIdx: number) => {
                            localFotoList.push({fotoDescription: foto.fotoDescription, fileName: "foto/" + foto.fileName});
                        })
                        return (
                            <div className={appStyles.divSection} key={"sectionDiv_" + sectionIdx}>
                                <h4>{sectionItem.sectionNr}. {sectionItem.sectionTitle}</h4>
                                <div className={appStyles.divFotoBlock}>
                                    <ul key={"clsb_ul"} className={appStyles.fotoListUl}>
                                        {sectionItem.fotoList.map((fotoItem: IFotoItem, fotoIdx: number) => {
                                            return (
                                                <li className={appStyles.divFoto} key={"fotoDiv_" + sectionIdx + "_" + fotoIdx} onClick={(e) => this.openBigImageNavigator(e, localFotoList, fotoIdx)}>
                                                    {fotoItem.fileName.toLowerCase().endsWith("mp4") &&
                                                        <ReactPlayer url={"foto/" + fotoItem.fileName} controls={true} className={appStyles.imgFoto} />
                                                    }
                                                    {!fotoItem.fileName.toLowerCase().endsWith("mp4") &&
                                                        <img src={"foto/" + fotoItem.fileName} alt={"foto"} className={appStyles.imgFoto} />
                                                    }
                                                    <div className={appStyles.divFotoDesc}>
                                                        {fotoItem.fotoDescription}
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                
                                <br />
                            </div>
                        )
                    })}
                
                </div>
                
                {showBigFotoNavigator && showBigFotoNavigator === true &&
                    <BigFotoNavigator fotoList={fotoList} selectedFotoIdx={selectedFotoIdx}  fnModalClose={this.closeBigFotoNavigator} />
                }
            
            </>
        )
    }
    
}