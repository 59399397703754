import React from 'react';
import {Table} from "react-bootstrap";

import musicStyles from "../../css/music.module.css";
import {IArtistItem, IArtistItems} from "../../typeDef/artistData";

interface IProps {
    artistDataList?: IArtistItems;
    fnSelectArtist: (c: IArtistItem) => void;
}

export class ArtistListing extends React.Component<IProps, {}> {
    
    render() {
        const { artistDataList, fnSelectArtist } = this.props;
        
        const artList: IArtistItems = artistDataList ? artistDataList : [];
        
        return (
            <>
                
                {/*
                -- List all Clubs ------------------------------------------------------------------------------------
*/}
                
                <div className={"newLine"}>
                    <br/>
                </div>
                
                <Table width={"100%"}>
                    <colgroup>
                        <col width={"30%"} />
                        <col width={"10%"} />
                        <col width={"20%"} />
                        <col width={"30%"} />
                        <col width={"10%"} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>
                            Artist / Name
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            # Songs
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {artList.map((artist: IArtistItem, artIdx) => {
                        return (
                            <tr key={"artTable_TR_" + artIdx} onClick={() => fnSelectArtist(artist)}>
                                <td>
                                    {artist.artistName}
                                </td>
                                <td>
                                    {artist.artistType}
                                </td>
                                <td>
                                    {artist.artistDesc}
                                </td>
                                <td>
                                    ???
                                </td>
                                <td />
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                
            
            </>
        )
    }
}
