import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import {useEffect} from "react";
import {createRoot} from "react-dom/client";

function AppWithCallbackAfterRender() {
    return (
        <div>
            <App />
        </div>
    )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);