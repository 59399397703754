import {actionCommands} from "../actionCommands";

export const APPDATA_SETDATAFULL = (entity: string, data: any[]) => {
    return {
        type: actionCommands.appData.setDataFull,
        payload: {entity, data}
    };
};

export const APPDATA_ADDDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.addDataItem,
        payload: {entity, data, idName}
    };
}

export const APPDATA_UPDATEDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.updateDataItem,
        payload: {entity, data, idName}
    };
}

export const APPDATA_DELETEDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.deleteDataItem,
        payload: {entity, data, idName}
    };
}