import {actionCommands} from "../actionCommands";

export const USER_LOGIN = (user: any) => {
    return {
        type: actionCommands.user.login,
        payload: user
    };
};

export const USER_LOGOFF = () => {
    return {
        type: actionCommands.user.logoff
    };
};
