import React from 'react';
import ReactPlayer from "react-player";
import {Accordion, Alert, Button, Col, FloatingLabel, Form, Row, Table} from "react-bootstrap";
import {IPlayListItems, IPlayListItem, IPlayListStaticData} from "../../typeDef/playList";
import {getlocalHtdocsPath} from "../../../sl6/config/getConfigData";
import musicStyles from "../../css/music.module.css";

interface IState {
    currentSong: IPlayListItem;
    currentPlayList: IPlayListItems;
    currentIndex: number;
    manualScrollPos: number;
    playListStaticData: IPlayListStaticData;
    
}

const prevBtn = require("../../../../img/prev_24px.png");
const nextBtn = require("../../../../img/next_24px.png");
const playListShowOld: number = 5;
const playListShowNumber: number = 25;
const emptyIPlayListItem: IPlayListItem = null;
const emptyPlayListItems: IPlayListItems = [];
const emptyPlayListStaticData: IPlayListStaticData = {
    artistData: [],
    allMamaFav: [],
}

const artistDir = "http://localhost/htdocs/music/artist/";

export class PlayList extends React.Component<{}, IState> {
    
    public state = {
        currentSong: emptyIPlayListItem,
        currentIndex: 0,
        manualScrollPos: 0,
        currentPlayList: emptyPlayListItems,
        playListStaticData: emptyPlayListStaticData
    }
    
    public componentDidMount() {
        this.api<{ data: IPlayListStaticData }>('/music/playListStaticData.json')
            .then(({ data }) => {
            })
            .catch(error => {
                alert ("Playlist Daten konnten nicht gelesen werden (Fehler).")
                console.log(error);
            })
    }
    
    private  api<T>(url: string): Promise<T> {
        return fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => { /* <-- data inferred as { data: T }*/
                this.setState({playListStaticData: data});
                return data
            })
    }
    
    private shufflePlayList = (playlist: IPlayListItems): IPlayListItems => {
        const remainingPlayList: IPlayListItems = playlist.map((item: any) => {
            return item
        });
        const shuffledPlayList: IPlayListItems = [];
        
        while (remainingPlayList.length > 0) {
            const randNumber = Math.floor(Math.random() * remainingPlayList.length);
            shuffledPlayList.push(remainingPlayList[randNumber]);
            remainingPlayList.splice(randNumber, 1);
        }
        return shuffledPlayList;
    }
    
    private playNextInList = (): void => {
        const {currentIndex, currentPlayList} = this.state;
        if (currentIndex + 1 < currentPlayList.length) {
            this.setState({currentIndex: currentIndex + 1, currentSong: currentPlayList[currentIndex + 1]});
        }
    }
    
    private selectSongToPlay = (index: number): void => {
        const { currentPlayList } = this.state;
        if (index >= 0 && index < currentPlayList.length) {
            this.setState({currentSong: currentPlayList[index], currentIndex: index});
        }
    }
    
    private selectMamaFavPlayList = (): void => {
        const {playListStaticData} = this.state;
        const playList: IPlayListItems = this.shufflePlayList(playListStaticData.allMamaFav);
        const currentSong: IPlayListItem = playList.length > 0 ? playList[0] : emptyIPlayListItem;
        this.setState({currentPlayList: playList, currentIndex: 0, currentSong});
    }
    
    private cleanPlaylist = (): void => {
        this.setState({currentPlayList: [], currentSong: null, currentIndex: 0})
    }
    
    render() {
        const {currentSong, currentIndex, currentPlayList} = this.state;
        
        let currentFilename: string = "";
        if (currentSong) {
            currentFilename = getlocalHtdocsPath() + "music/artist/artist_" + currentSong.artistId + "/" + currentSong.fileName;
        }
        
        const showFromPos = currentIndex - playListShowOld < 0 ? 0 : currentIndex - playListShowOld;
        const showPlayListEntries = [];
        
        for (let splIdx = showFromPos; splIdx <= showFromPos + playListShowNumber - 1; splIdx++) {
            if (splIdx + 1 <= currentPlayList.length) {
                showPlayListEntries.push(splIdx);
            }
        }
        
        return (
            <>
                {/* -- Brot-Krümel: Titel-Navigation -------------------------------------------------------------- */}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"}>
                        Play List
                    </div>
                </div>
                
                <div>
    
                    <table width={"100%"}>
                        <colgroup>
                            <col width={"50%"}/>
                            <col width={"50%"}/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <td>
                                {currentSong &&
                                    <>
                                        <br />
                                        <div>
                                            <ReactPlayer url={currentFilename} controls={true}
                                                         className={musicStyles.realPlayerMusicPlayList}
                                                         onEnded={this.playNextInList}
                                                         playing={true}/>
                                        </div>
                                        <div>
                                            <br/>
                                            <Form>
                                                <fieldset disabled={false}>
                                                    <Form.Group as={Row} size={"sm"} className={"mb-1"} controlId={"formArtist"}>
                                                        <Col sm={9} size={"sm"}>
                                                            <FloatingLabel
                                                                controlId={"flaotingArtistName"}
                                                                label={"Artist Name"}
                                                            >
                                                                <Form.Control size={"sm"} type={"text"} value={currentSong.artistName}  readOnly={true} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col sm={1} size={"sm"}/>
                                                    </Form.Group>
                                                    <Form.Group as={Row} size={"sm"} className={"mb-1"} controlId={"formSongTitle"}>
                                                        <Col sm={9} size={"sm"}>
                                                            <FloatingLabel
                                                                controlId={"flaotingSongTitle"}
                                                                label={"Song Titel"}
                                                            >
                                                                <Form.Control size={"sm"} type={"text"} value={currentSong.songTitle} readOnly={true} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col sm={1} size={"sm"}/>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className={"mb-1"} controlId={"formSongTitle"}>
                                                        <Col sm={2} size={"sm"}>
                                                            <FloatingLabel
                                                                controlId={"flaotingSongDauer"}
                                                                label={"Dauer"}
                                                            >
                                                                <Form.Control size={"sm"} type={"text"} value={currentSong.songDuration} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col sm={1} size={"sm"}/>
                                                        <Col sm={2} size={"sm"}>
                                                            <FloatingLabel
                                                                controlId={"flaotingSongJahr"}
                                                                label={"Jahr"}
                                                            >
                                                                <Form.Control size={"sm"} type={"text"} value={currentSong.originYear} readOnly={true} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col sm={1} size={"sm"}/>
                                                        <Col sm={2} size={"sm"} className={"mt-sm-3"}>
                                                            <Button variant={"outline-dark"} ><img src={prevBtn} alt={"Prev"} onClick={() => this.selectSongToPlay(currentIndex - 1)} /></Button>
                                                        </Col>
                                                        <Col sm={2} size={"sm"} className={"mt-sm-3"}>
                                                            <Button variant={"outline-dark"} ><img src={nextBtn} alt={"Bext"} onClick={() => this.selectSongToPlay(currentIndex + 1)} /></Button>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className={"mb-3"} controlId={"formSongTitle"}>
                                                        <Form.Text className={"text-muted"}>
                                                            <img src={artistDir + "artist_" + currentSong.artistId + "/foto.png"} alt={currentSong.artistName} className={musicStyles.artistPhotoImg} />
                                                        </Form.Text>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form>
                                        </div>
                                    </>
                                }
                                {currentSong === null &&
                                    <Alert key={"NoCurrentSongRealPlayerinfo"} variant={"info"}>
                                        Du hast noch keine Songlist ausgewählt oder zusammengestellt. Daher ist der Player auch nicht sichtbar.
                                    </Alert>
                                }
                                <br />
                                <Button variant="outline-dark" onClick={this.selectMamaFavPlayList}>Playlist auswählen</Button> &nbsp;
                                <Button variant="outline-dark">selber zusammenstellen</Button> &nbsp;
                                <Button variant="outline-dark" onClick={this.cleanPlaylist}>Playlist leeren</Button> &nbsp;
                            
                            </td>
                            <td>
                                <Table size={"sm"}>
                                    <colgroup>
                                        <col width={"5%"} />
                                        <col width={"70%"} />
                                        <col width={"10%"} />
                                        <col width={"15%"} />
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Artist / Titel
                                        </th>
                                        <th>
                                            Jahr
                                        </th>
                                        <th>
                                            Dauer
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {showPlayListEntries.map((splIdx) => {
                                        const splItem = currentPlayList[splIdx];
                                        const classTdItem = splItem.num === currentSong.num ? musicStyles.playListIsCurrentTd : musicStyles.playListIsNormalTd;
                                        return (
                                            <tr key={"splItem_tr_" + splItem.num} onClick={() => this.selectSongToPlay(splIdx)}>
                                                <td className={classTdItem}>
                                                    {splIdx + 1}.
                                                </td>
                                                <td className={classTdItem}>
                                                    {splItem.artistName} / {splItem.songTitle}
                                                </td>
                                                <td className={classTdItem}>
                                                    {splItem.originYear}
                                                </td>
                                                <td className={classTdItem}>
                                                    {splItem.songDuration}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            
                            </td>
                        </tr>
                        
                        </tbody>
                    </table>
                    
                
                </div>
            
            
            </>
        )
    }
}
