import React from 'react';
//import PropTypes from 'prop-types';

import {IDataItem, IFotoItem, IFotoItems, ISectionItem, ISectionItems} from "../../typeDef/dataData";
import {Card, Col, Row} from "react-bootstrap";
import appStyles from "../../css/app.module.css";
import {BigFotoNavigator} from "../../../steuern2021/start/BigFotoNavigator";

interface IProps {
    sectionItem: ISectionItem;
}

interface IState {
    showBigFotoNavigator: boolean;
    fotoList: IFotoItems;
    selectedFotoIdx: number;
}

const fotoPrefix = "/foto/";


export default class SectionDetails extends React.Component<IProps, IState> {
    
    public state = {
        showBigFotoNavigator: false,
        fotoList: [] as IFotoItems,
        selectedFotoIdx: 0,
    }
    
    openBigImageNavigator = (e: any, fotoList: IFotoItems, fotoIdx: number) => {
        e.preventDefault();
        console.log("openBigImageNavigator: " + fotoIdx);
        console.log(fotoList);
        const newFotoList: IFotoItems = [];
        fotoList.forEach((foto: IFotoItem) => {
            const newFoto: IFotoItem = Object.assign({}, foto);
            newFoto.fileName = fotoPrefix + newFoto.fileName;
            newFotoList.push(newFoto);
        } )
        console.log("newFotoList");
        console.log(newFotoList);
        this.setState({showBigFotoNavigator: true, fotoList: newFotoList, selectedFotoIdx: fotoIdx});
    }

    closeBigFotoNavigator = () => {
        this.setState({showBigFotoNavigator: false, fotoList: [], selectedFotoIdx: null});
    }
    
    render() {
        const {sectionItem} = this.props;
        const {showBigFotoNavigator, selectedFotoIdx} = this.state;
        
        
        console.log("SectionDetails.render()");
        console.log("sectionItem:");
        console.log(sectionItem);
        
        const fotoList: IFotoItems = sectionItem.fotoList ? sectionItem.fotoList : [];
        const newFotoList: IFotoItems = [];
        fotoList.forEach((foto: IFotoItem) => {
            const newFoto: IFotoItem = Object.assign({}, foto);
            newFoto.fileName = fotoPrefix + newFoto.fileName;
            newFotoList.push(newFoto);
        } )
    
        return (
            <>
                
                <table className={appStyles.descriptionTable}>
                    <colgroup>
                        <col width={"30%"} />
                        <col width={"70%"} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td>Description:</td>
                        <td>{sectionItem.sectionDescription}</td>
                    </tr>
                    <tr>
                        <td>Link:</td>
                        <td>
                            <a href={sectionItem.sectionLink} target={"_blank"}>{sectionItem.sectionLinkLabel}</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br />
    
                <Row xs={4} md={4} xl={4} className="g-4">
                    <>
                        {newFotoList.length === 0 &&
                            <Card>
                                <Card.Body>There are no photos for that section</Card.Body>
                            </Card>
                        }
                        {newFotoList.map((foto: IFotoItem, fotoIdx) => {
                            return (
                                <React.Fragment
                                    key={"fbFoto_Col_" + fotoIdx}>
                                    <Col>
                                        <Card onClick={(e) => this.openBigImageNavigator(e, newFotoList, fotoIdx)} >
                                            <Card.Img variant="top" src={foto.fileName}
                                                      className={appStyles.imgFoto}/>
                                            {foto.fotoDescription && foto.fotoDescription !== "" &&
                                                <Card.Body>
                                                    <Card.Text>
                                                        {foto.fotoDescription}
                                                    </Card.Text>
                                                </Card.Body>
                                            }
                                        </Card>
                                    </Col>
                                </React.Fragment>
                                )
                        })}
                    </>
                </Row>
    
                {showBigFotoNavigator && showBigFotoNavigator === true &&
                    <BigFotoNavigator fotoList={newFotoList} selectedFotoIdx={selectedFotoIdx} fnModalClose={this.closeBigFotoNavigator} />
                }
                
            </>
        )
    }
}

