import React from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {IMessageList} from "../../../../typeDef/messageList";
import {MessageList} from "../../../../component/messageList/MessageList";
import slmServicePath from "../../config/slmServices.json";
import {IRootState} from "../../../../redux";
import {connect} from "react-redux";
import { appDataMapDispatchToProps } from "../../../../redux/appDataMapDispatchToProps";
import {ArtistListing} from "./ArtistListing";
import {IArtistItem} from "../../typeDef/artistData";
import {ISongItem} from "../../typeDef/songData";
import {ArtistDetails} from "./ArtistDetails";
import {IGirlItem} from "../../../sl6/typeDef/girlData";
import {ArtistDataExport} from "./ArtistDataExport";

interface IApiFeedback {
    state: string;
    error: string;
    msgList?: IMessageList
}

interface IState {
    selectedArtistId: number;
    editArtist: IArtistItem;
    selectedSongId: number;
    apiFeedback: IApiFeedback;
    showExportDialog: boolean;
}


const mapStateToProps = (state: IRootState) => {
    return {
        adSlmArtist: state.appData.slmArtist,
        adSlmSong: state.appData.slmSong
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

const emptyMsgList: IMessageList = [];
const emptyApiFeedback: IApiFeedback = null;
const emptyArtist: IArtistItem = null;

class ArtistComponent extends React.Component<Props, IState> {

    public state = {
        selectedArtistId: 0,
        editArtist: emptyArtist,
        selectedSongId: 0,
        apiFeedback: emptyApiFeedback,
        showExportDialog: false,
    }

    componentDidMount = () => {
        this.loadAllArtistListing()
    }
    
    private callbackLoadAllArtist = (sc: ServiceCall) => {
        const apiFeedback: IApiFeedback = {
            state: "loaded",
            error: sc.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            msgList: sc.getResponseInfo(),
        }
        this.props.setDataFull("slmArtist", sc.getResponseData());
        this.setState({apiFeedback});
    }
    private loadAllArtistListing = () => {
        const cs = new ServiceCall(slmServicePath.allArtistListing);
        cs.executePost({}, this.callbackLoadAllArtist);
    }
    
    private selectArtist = (artist: IArtistItem): void => {
        if (artist) {
            this.setState({selectedArtistId: artist.artistId, selectedSongId: 0, editArtist: artist})
            this.props.setDataFull("slmSong", artist.songList);
        } else {
            this.setState({selectedArtistId: 0, selectedSongId: 0, editArtist: emptyArtist})
        }
        
    }
    
    private editArtistChangeHandler = (action: string, field: string, newValue: any): void => {
        const { selectedArtistId, editArtist } = this.state;
        const { adSlmArtist } = this.props;
        if (action === 'reset') {
            if (editArtist.wsMode === 'A') {
                // spezial-Fall 'Add' --> wieder zurück auf Liste
                this.setState({selectedArtistId: 0})
            } else {
                // Normal-Fall 'Edit' --> einfach neu aus Store auslesen
                editArtist.wsMode = "";
                let selectedArtistFromStore = adSlmArtist.find((art: IArtistItem) => art.artistId === selectedArtistId);
                if (selectedArtistFromStore) {
                    this.selectArtist(selectedArtistFromStore);
                }
            }
        } else if (action === 'change') {
            const { editArtist } = this.state;
            // @ts-ignore
            editArtist[field] = newValue;
            this.setState({editArtist});
        }
    }
    
    private exportArtistData = (): void => {
        this.setState({showExportDialog: true});
    }
    private closeExportArtistData = (): void => {
        this.setState({showExportDialog: false});
    }
    
    
    render() {
        const { selectedArtistId, apiFeedback, selectedSongId, editArtist, showExportDialog } = this.state;
        const { adSlmArtist } = this.props;
        
        let action = "artistList";
        if (selectedSongId > 0) {
            action = "songDetail";
        } else if (selectedArtistId > 0) {
            action = "artistDetail";
        }
    
        let selectedArtistFromStore = emptyArtist;
        if (adSlmArtist) {
            selectedArtistFromStore = adSlmArtist.find((art: IArtistItem) => art.artistId === selectedArtistId);
            if (!selectedArtistFromStore) {
                action = "artistList";
            }
        }
        
        let showArtist: IArtistItem = selectedArtistFromStore;
        if (editArtist && (editArtist.wsMode === "A" || editArtist.wsMode === "U")) {
            showArtist = editArtist;
        }
    
        return (
            <>
{/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectArtist(null)}>
                        all Artists
                    </div>
                    {selectedArtistId > 0 && selectedArtistFromStore &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"} onClick={() => this.selectArtist(selectedArtistFromStore)}>
                                {selectedArtistFromStore.artistName}
                            </div>
                        </>
                    }
                    <div style={{float: "right"}} onClick={this.exportArtistData}>Export</div>
                </div>
    
                {apiFeedback && apiFeedback.msgList &&
                    <MessageList msgList={apiFeedback.msgList} />
                }
                {action === "artistList" &&
                    <ArtistListing fnSelectArtist={this.selectArtist} artistDataList={adSlmArtist} />
                }
                {action === "artistDetail" &&
                    <ArtistDetails fnSelectArtist={this.selectArtist} showArtist={showArtist} fnChangeHandler={this.editArtistChangeHandler} />
                }
    
                {showExportDialog &&
                    <ArtistDataExport fnModalClose={this.closeExportArtistData} artistData={adSlmArtist}/>
                }
                
            </>
        )
    }
}

export const ArtistContainer = connect(mapStateToProps, appDataMapDispatchToProps)(ArtistComponent);
