import * as React from 'react';
import {Button, Modal} from "react-bootstrap";

import fileUploaderStyles from "./fileUploader.module.css";
import {ServiceCall} from "../../sharedLib/ServiceCall";
import sl6ServicePath from "../../app/sl6/config/sl6Services.json";
import styles from "../../app/sl6/pages/club/club.module.css";

interface IImageFileItem {
    id: number;
    fileName: string;
    image: any;
    processState: string;
}

interface IProps {
    fnModalClose: () => void;
    previewSize: string;
    uploadType: string;
    uploadId: any;
    onFileUploadCompleted: () => void;
    phpExecuteProcedure: string;
}

interface IState {
    lastUpdate: Date;
    dragActive: boolean;
    imageFileCounterState: number;
    previewSize: string;
    hasUploaded: boolean;
}

let imageFileItems: IImageFileItem[] = [];
let imageFileCounter: number = 0;

const emptyImageFileItems: IImageFileItem[] = [];

export class FileUploaderModal extends React.Component<IProps, IState> {
    
    
    public state = {
        lastUpdate: new Date(),
        hasUploaded: false,
        dragActive: false,
        imageFileCounterState: imageFileCounter,
        previewSize: this.props.previewSize,
    }
    
    handleClose = () => {
        const {hasUploaded} = this.state;
        imageFileItems = [];
        this.props.fnModalClose();
        if (hasUploaded === true) {
            this.props.onFileUploadCompleted();
        }
    }
    
    private onFileChange = (e: any): void => {
        const files: any[] = e.target.files;
        for (let fno= 0; fno < files.length; fno++) {
            console.log("File-No: " + fno);
            const file = files[fno];
            const filename = file.name;
            console.log(file);
            console.log("file.filename: " + filename);
        
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (event) => {
                imageFileCounter++;
                console.log("onload - filename: " + filename);
                const imageFileItem: IImageFileItem = {
                    id: imageFileCounter,
                    fileName: filename,
                    image: event.target.result,
                    processState: "pending",
                }
                console.log("onload - imageFileItem:");
                console.log(imageFileItem);
                imageFileItems.push(imageFileItem);
                this.setState({imageFileCounterState: imageFileCounter, lastUpdate: new Date()})
                this.submitSingleImageUpload(imageFileItem);
            }
        }
    }
    
    private callbackSubmitImageUpload = (sc: ServiceCall): void => {
        const statusCode = sc.getRequestConfigStatusCode();
        console.log("FileUploaderModal.callbackSubmitImageUpload():");
        if (statusCode === 0) {
            imageFileItems = [];
            this.props.fnModalClose();
            this.props.onFileUploadCompleted();
        } else {
            alert("Error:");
            console.log(sc);
        }
    }
    
    private callbackSubmitSingleImageUpload = (sc:ServiceCall): void => {
        const statusCode = sc.getRequestConfigStatusCode();
        console.log("FileUploaderModal.callbackSubmitImageUpload():");
        const fileId = sc.getRequestParam("id");
        const fileIndex = imageFileItems.findIndex((file: IImageFileItem) => file.id === fileId);
        if (statusCode === 0) {
            if (fileIndex >= 0) {
                imageFileItems[fileIndex].processState = "OK";
            }
        } else {
            if (fileIndex >= 0) {
                imageFileItems[fileIndex].processState = "Not OK";
            }
            alert("Error:");
            console.log(sc);
        }
        this.setState({lastUpdate: new Date(), hasUploaded: true})
    }
    
    private submitImageUpload = (): void => {
        const { uploadType, uploadId, phpExecuteProcedure} = this.props;
        const sc = new ServiceCall(phpExecuteProcedure);
        sc.executePost({type: uploadType, id: uploadId, imageFileItems}, this.callbackSubmitImageUpload);
    }
    
    private submitSingleImageUpload = (imageFileItem: IImageFileItem): void => {
        const { uploadType, uploadId, phpExecuteProcedure} = this.props;
        const sc = new ServiceCall(phpExecuteProcedure);
        sc.setRequestParam("id", imageFileItem.id);
        sc.executePost({type: uploadType, id: uploadId, imageFileItems: [imageFileItem]}, this.callbackSubmitSingleImageUpload);
        
    }
    
    private setPreviewSizeState = (value: string): void => {
        this.setState({previewSize: value});
    }
    
    private setDragActive = (newValue: boolean): void => {
        this.setState({dragActive: newValue});
    }
    
    private handleDrag = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            this.setDragActive(true);
        } else if (e.type === "dragleave") {
            this.setDragActive(false);
        }
    }
    
    private handleDrop = (e: any): void => {
        e.preventDefault();
        e.stopPropagation();
        this.setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const files: any[] = e.dataTransfer.files;
            for (let fno= 0; fno < files.length; fno++) {
                console.log("File-No: " + fno);
                const file = files[fno];
                const filename = file.name;
                console.log(file);
                console.log("file.filename: " + filename);
        
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = (event) => {
                    imageFileCounter++;
                    console.log("onload - filename: " + filename);
                    const imageFileItem: IImageFileItem = {
                        id: imageFileCounter,
                        fileName: filename,
                        image: event.target.result,
                        processState: "pending",
                    }
                    console.log("onload - imageFileItem:");
                    console.log(imageFileItem);
                    imageFileItems.push(imageFileItem);
                    this.setState({imageFileCounterState: imageFileCounter, lastUpdate: new Date()})
                    this.submitSingleImageUpload(imageFileItem);
                }
            }
        }
    }
    
    private clickOnHiddenInputFileUpload = (): void => {
        var element: any = document.querySelector('#inputFileUpload');
        element.click();
    }
    
    render() {
        const { previewSize, dragActive } = this.state;
        const show = true;
    
        let previewImageStyle = fileUploaderStyles.fileUploaderPreviewListImgLarge;
        if (previewSize === "s") {
            previewImageStyle = fileUploaderStyles.fileUploaderPreviewListImgSmall;
        } else if (previewSize === "m") {
            previewImageStyle = fileUploaderStyles.fileUploaderPreviewListImgMedium;
        }
        
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered={true} dialogClassName={fileUploaderStyles.modalWindow} >
                    <Modal.Header closeButton className={fileUploaderStyles.modalHeader}>
                        <Modal.Title>File-Uploader</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={fileUploaderStyles.modalBody}>
                        <div>
                            <div className="form-group mb-3">
                                <br />
                                <table width={"100%"}>
                                    <colgroup>
                                        <col width={"75%"} />
                                        <col width={"25%"} />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <form className={fileUploaderStyles.formFileUpload} onDragEnter={this.handleDrag} onSubmit={(e) => e.preventDefault()}>
                                                <input type={"file"} className={fileUploaderStyles.inputFileUpload} id={"inputFileUpload"} name={"image"} onChange={this.onFileChange} multiple={true} width={"100%"} />
                                                <label className={fileUploaderStyles.labelFileUpload}>
                                                    <div>
                                                        <p>Drag and drop your file here or </p>
                                                        <button className={fileUploaderStyles.uploadButton} onClick={this.clickOnHiddenInputFileUpload}>Upload a file</button>
                                                    </div>
                                                </label>
                                                { dragActive &&
                                                    <div className={fileUploaderStyles.dragFileElement}onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}>
                                                    
                                                    </div>
                                                }
                                            </form>
                                        </td>
                                        <td>
                                            Preview: &nbsp;
                                            <Button size={"sm"} variant={previewSize === "s" ? "dark" : "outline-dark"} onClick={() => this.setPreviewSizeState("s")}>Small</Button>
                                            <Button size={"sm"} variant={previewSize === "m" ? "dark" : "outline-dark"} onClick={() => this.setPreviewSizeState("m")}>Medium</Button>
                                            <Button size={"sm"} variant={previewSize === "l" ? "dark" : "outline-dark"} onClick={() => this.setPreviewSizeState("l")}>Large</Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
    
                            <div className={fileUploaderStyles.divImageList}>
                                <br/>
                                {imageFileItems.map((img, idx) => {
                                    let divStateStyle = {borderBottom: "#bb2d3b solid 8px"};
                                    if (img.processState === "OK") {
                                        divStateStyle = {borderBottom: "#20c997 solid 8px"};
                                    } else if (img.processState === "pending") {
                                        divStateStyle = {borderBottom: "cornsilk solid 8px"};
                                    }
                                    return (
                                        <React.Fragment key={"img_" + img.id}>
                                                <img key={"img_" + img.id} src={img.image} alt={img.fileName} className={previewImageStyle} style={divStateStyle}/>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            
                            {imageFileItems && imageFileItems.length > 0 &&
                                <div className={fileUploaderStyles.divNormal}>
                                    <br />
                                    <div className="d-grid">
                                        <Button type="submit" className="" onClick={this.submitImageUpload}>Image Upload</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    
}