import * as React from 'react';
import {Modal} from "react-bootstrap";
import ReactPlayer from 'react-player';

import appStyles from "../css/app.module.css";

interface IFotoItem {
    fileName?: string;
    fotoDescription?: string;
}
interface IFotoItems extends Array<IFotoItem>{}

interface IProps {
    fotoList: IFotoItems;
    selectedFotoIdx: number;
    fnModalClose: () => void;
}

interface IState {
    currentFotoIdx: number;
}

export  class BigFotoNavigator extends React.Component<IProps, IState> {
    
    public state = {
        currentFotoIdx: this.props.selectedFotoIdx,
    }
    
    handleClose = () => {
        this.props.fnModalClose();
    }
    
    goNextFoto = () => {
        let { currentFotoIdx } = this.state;
        const { fotoList } = this.props;
        currentFotoIdx++;
        if ((currentFotoIdx + 1) > fotoList.length) {
            currentFotoIdx = 0;
        }
        this.setState({currentFotoIdx});
    }
    
    goPrevFoto = () => {
        let { currentFotoIdx } = this.state;
        const { fotoList } = this.props;
        currentFotoIdx--;
        if (currentFotoIdx < 0 ) {
            currentFotoIdx = fotoList.length - 1;
        }
        this.setState({currentFotoIdx});
    }
    
    
    render() {
        const { fotoList, selectedFotoIdx } = this.props;
        const { currentFotoIdx } = this.state;
        const show = true;
        const currentFoto = fotoList[currentFotoIdx];
        const fotoNr: number = currentFotoIdx + 1;
        const fotoNrString = fotoNr.toString();
        console.log("BigFotoNavigator.render");
        console.log(fotoList);
        
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered={true} dialogClassName={appStyles.modalBigFotoNavigator} >
                    <Modal.Header closeButton className={appStyles.modalHeaderBigFotoNavigator}>
                        Bild Navigator: Foto: {currentFotoIdx + 1} / {fotoList.length}
                    </Modal.Header>
                    <Modal.Body className={appStyles.modalBodyBigFotoNavigator}>
                        <table className={appStyles.carouselTabBigFotoNavigator}>
                            <tbody>
                            <tr>
                                <td className={appStyles.carouselLeftRightTd} onClick={this.goPrevFoto}>
                                    <img src={require("../img/arrowPrevLeft32.png")} alt={"Prev"} title={"Vorheriges Bild"} />
                                </td>
                                <td className={appStyles.carouselImageTd}>
                                    {currentFoto.fileName.toLowerCase().endsWith("mp4") &&
                                        <ReactPlayer url={currentFoto.fileName} controls={true} className={appStyles.realPlayerBigFotoNavigator} />
                                    }
                                    {!currentFoto.fileName.toLowerCase().endsWith("mp4") &&
                                        <img
                                            className={appStyles.imgBildNavigator}
                                            src={currentFoto.fileName}
                                            alt={fotoNrString}
                                        />
                                    }
                                    <p><br />{currentFoto.fotoDescription}</p>
                                </td>
                                <td className={appStyles.carouselLeftRightTd} onClick={this.goNextFoto}>
                                    <img src={require("../img/arrowNextRight32.png")} alt={"Next"} title={"Nächstes Bild"} />
                                </td>
                            </tr>
                            </tbody>
                        
                        </table>
                    
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    
}