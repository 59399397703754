import appDataList from "./../../config/applicationList.json";

import {IAppData} from '../typeDef/appData';

export function getApplication(locPathName: string): IAppData {
    
    let retApplData: IAppData = {
        techName: "",
        urlPath: "/",
        appTitle: "STELU Applications Portal",
        hasLogin: false,
        topNav: []
    }
    
    if (locPathName && locPathName !== "") {
        appDataList.forEach(app => {
            if (locPathName.startsWith(app.urlPath)) {
                retApplData = app;
            }
        })
    }
    
    return retApplData;
}
