import React from 'react';
import {Tab, Table, Tabs, Accordion, Stack, Card, CardGroup, Row, Col} from "react-bootstrap";

import {ITopicItems, ITopicItem} from "../../typeDef/topicData";
import {ITopicPartItems, ITopicPartItem, IFotobookMediumItem} from "../../typeDef/topicPartData";
import appStyles from "../../../fotobook/css/app.module.css";
import {TopicPage} from "../../../steuern2021/start/TopicPage";
import {FileUploader} from "../../../../component/fileUploader/FileUploader";
import sl6ServicePath from "../../../sl6/config/sl6Services.json";
import slfServicePath from "../../../fotobook/config/slfServices.json";
import ReactPlayer from "react-player";
import {getlocalHtdocsPath} from "../../../sl6/config/getConfigData";
import {getFileType} from "../../../../sharedLib/fileLib";
import {IFileItem} from "../../../../typeDef/fileItem";
import {BigFotoNavigator} from "../../../steuern2021/start/BigFotoNavigator";

interface IFotoItem {
    fileName?: string;
    fotoDescription?: string;
}
interface IFotoItems extends Array<IFotoItem>{}

interface IProps {
    topicPartDataList?: ITopicPartItems;
    isEditable: number;
}

interface IState {
    showBigFotoNavigator: boolean;
    fotoList?: IFotoItems;
    selectedFotoIdx: number;
    
}

export class TopicPartListing extends React.Component<IProps, IState> {
    
    public state = {
        showBigFotoNavigator: false,
        fotoList: [] as IFotoItems,
        selectedFotoIdx: 0,
    }
    
    openBigImageNavigator = (e: any, fotoList: IFotoItems, fotoIdx: number) => {
        e.preventDefault();
        console.log("openBigImageNavigator: " + fotoIdx);
        console.log(fotoList);
        this.setState({showBigFotoNavigator: true, fotoList, selectedFotoIdx: fotoIdx});
    }
    
    closeBigFotoNavigator = () => {
        this.setState({showBigFotoNavigator: false, fotoList: [], selectedFotoIdx: null});
    }
    
    private getReducedTopicPartString = (subject: string): string => {
        let retString: string = "";
        if (subject.length > 25) {
            retString = subject.substring(0, 22) + "...";
        } else {
            retString = subject;
        }
        return retString;
    }
    
    private callbackFileUploadCompleted = (): void => {
        console.log("TopicPartListing.callbackFileUploadCompleted");
    }
    
    render() {
        const {topicPartDataList, isEditable} = this.props;
        const {showBigFotoNavigator, fotoList, selectedFotoIdx} = this.state;
        
        const topicPartList: ITopicPartItems = topicPartDataList ? topicPartDataList : [];
        const topicId: number = topicPartDataList &&  topicPartDataList.length > 0 ? topicPartDataList[0].fotobookTopicId : 0;
        const localRootDir = getlocalHtdocsPath() + "fotobook/";
        const colPerRow: number = Math.floor(window.innerWidth / 320);
        
        
        
        return (
            <>
                
                {/*
                -- List all Topics-Parts ------------------------------------------------------------------------------------
*/}
                
                <div className={"newLine"}>
                    <br/>
                </div>
                
                
                <Accordion>
                    {topicPartList.map((part: ITopicPartItem) => {
                        const localFotoList: IFotoItems = [];
                        part.fbMediumList.forEach((med: IFotobookMediumItem) => {
                            localFotoList.push({fotoDescription: med.fileDescription, fileName: localRootDir + "topic_" + topicId + "/topicpart_" + med.topicPartId + "/" + med.fileName})
                        })
    
                        return (
                            <Accordion.Item eventKey={"" + part.topicPartId}
                                            key={"topicPartAccordion_" + part.topicPartId}>
                                <Accordion.Header>
                                    <b>{part.orderNum}. {part.partSubject}</b>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row xs={colPerRow} md={colPerRow} xl={colPerRow} className="g-4">
                                        {part.fbMediumList.length === 0 &&
                                            <Card>
                                                <Card.Body>There are no data for that topic/part</Card.Body>
                                            </Card>
                                        }
                                        {part.fbMediumList.map((med: IFotobookMediumItem, fotoIdx) => {
                                            const fileName = localRootDir + "topic_" + topicId + "/topicpart_" + med.topicPartId + "/" + med.fileName;
                                            let ending: string = "";
                                            if (med.fileName && med.fileName !== "") {
                                                const fileNameSplit = med.fileName.split(".");
                                                ending = fileNameSplit[fileNameSplit.length - 1];
                                            }
                                            const file: IFileItem = {
                                                isDir: false,
                                                dir: localRootDir + "topicpart_" + med.topicPartId + "/",
                                                ending: ending,
                                                filename: med.fileName
                                            };
                                            const imageFileType = getFileType(file);
                                            if (imageFileType === "image") {
                                                return (
                                                    <React.Fragment
                                                        key={"fbMediumList_Col_" + med.topicPartId + "_" + med.fbMediumId}>
                                                        {med.fileDescription && med.fileDescription !== "" &&
                                                            <Col>
                                                                <Card>
                                                                    <Card.Body>
                                                                        <Card.Text>
                                                                            {med.fileDescription}
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        }
                                                        <Col>
                                                            <Card onClick={(e) => this.openBigImageNavigator(e, localFotoList, fotoIdx)}>
                                                                <Card.Img variant="top" src={fileName}
                                                                          className={appStyles.imgFoto}/>
                                                            </Card>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            }
                                            if (imageFileType === "video") {
                                                return (
                                                    <React.Fragment
                                                        key={"fbMediumList_Col_" + med.topicPartId + "_" + med.fbMediumId}>
                                                        {med.fileDescription && med.fileDescription !== "" &&
                                                            <Col>
                                                                <Card>
                                                                    <Card.Body>
                                                                        <Card.Text>
                                                                            {med.fileDescription}
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        }
                                                        <Col>
                                                            <Card border={"secondary"}>
                                                                <Card.Body className={appStyles.imgFoto}
                                                                           style={{verticalAlign: "top"}}>
                                                                    <ReactPlayer url={fileName} controls={true}
                                                                                 width={"270px"}/>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            }
                                        })}
                                    </Row>
                                    {isEditable !== null && isEditable === 1 &&
                                        <FileUploader iconSize={"l"} previewSize={"m"} uploadType={"slfFbMedium"}
                                                      uploadId={part.topicPartId}
                                                      onFileUploadCompleted={this.callbackFileUploadCompleted}
                                                      phpExecuteProcedure={slfServicePath.fbMediumFileUploader}/>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
    
                {showBigFotoNavigator && showBigFotoNavigator === true &&
                    <BigFotoNavigator fotoList={fotoList} selectedFotoIdx={selectedFotoIdx} fnModalClose={this.closeBigFotoNavigator} />
                }


            </>
        )
    }
}
