import React from 'react';

import musicStyles from "../../css/music.module.css";
import {IArtistItem} from "../../typeDef/artistData";
import {ArtistDetailsArtistBlock} from "./ArtistDetailsArtistBlock";
import {ArtistDetailsSongBlock} from "./ArtistDetailsSongBlock";

interface IProps {
    fnSelectArtist: (c: IArtistItem) => void;
    showArtist: IArtistItem;
    fnChangeHandler: (action: string, name?: string, newValue?: any) => void;
    
}

export class ArtistDetails extends React.Component<IProps, {}> {
    
    
    render() {
        const { showArtist, fnChangeHandler } = this.props;
        
        return (
            <>
                <br/>
                <div>
                    <ArtistDetailsArtistBlock showArtist={showArtist} fnChangeHandler={fnChangeHandler} />
                    <hr />
                </div>
                <div>
                    <ArtistDetailsSongBlock showArtist={showArtist} fnChangeHandler={fnChangeHandler} />
                    <hr />
                    
                </div>
                
{/*
                <table className={musicStyles.artistDetailsOuterTable} >
                    <tbody>
                    <tr>
                        <td className={musicStyles.artistDetailsOuterTableAristBlockTd}>
                            <div className={musicStyles.divSmallHeader}>
                                Artist
                            </div>
                            <br/>
                            <ArtistDetailsArtistBlock showArtist={showArtist} fnChangeHandler={fnChangeHandler} />
                        </td>
                        <td className={musicStyles.artistDetailsOuterTableAristSongTd}>
                            <div className={musicStyles.divSmallHeader}>
                                Song(s)
                            </div>
                            <br/>
                            <ArtistDetailsSongBlock showArtist={showArtist} fnChangeHandler={fnChangeHandler} />
                        
                        </td>
                    </tr>
                    </tbody>
                </table>
*/}
            
            
            
            </>
        )
    }
}
