

const keyList = ["0", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
                      "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
                      "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const keyLength = keyList.length;

console.log("[Debug] Initiating getIdLib ...");

function getKeyPartString(numValue, numPlaces) {
    var retValue = "";
    var remValue;
    var divRestValue;
    var divIntValue;
    var roundNum = 0;

    remValue = numValue;
    do {

        roundNum++;
        divRestValue = remValue % keyLength;
        divIntValue = Math.floor(remValue/ keyLength);

        const key = keyList[divRestValue];
        retValue = key + retValue;
        remValue = divIntValue;
    } while (remValue > 0 && roundNum < 100);

    while (retValue.length < numPlaces) {
        retValue = "0" + retValue;
    }
    return retValue;
}

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
}

export function getNewId () {
    var newId = "";

    console.log("keyLength: " + keyLength);
    const todayWithTime = new Date();
    const todayNoTime = new Date(todayWithTime.getFullYear(),
                                 todayWithTime.getMonth(),
                                 todayWithTime.getDate(),
                                 0, 0, 0);
    const timeSinceMidnight = todayWithTime.getTime() - todayNoTime.getTime();

    // ID: Random-Number 1 - Date - Time - Random-Number 2
    const randomNumOne = getRndInteger(Math.pow(keyLength, 3) + 10, Math.pow(keyLength, 4) - 10);
    const randomNumTwo = getRndInteger(Math.pow(keyLength, 3) + 10, Math.pow(keyLength, 4) - 10);

    const dateNumber = (todayNoTime.getDate() * 1) +
                       ((todayNoTime.getMonth() + 1) * 100 ) +
                       (todayNoTime.getFullYear() * 10000);
    console.log("dateNumber: " + dateNumber);

    newId = getKeyPartString(randomNumOne, 0) + "-" +
            getKeyPartString(dateNumber, 0) + "-" +
            getKeyPartString(timeSinceMidnight, 0) + "-" +
            getKeyPartString(randomNumTwo, 0);
    // getKeyPartString(20220921, 5);
    console.log("newId: " + newId);

    return newId;
}
