import React from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {IMessageList} from "../../../../typeDef/messageList";
import {Button} from "react-bootstrap";
import sl6ServicePath from "../../config/sl6Services.json";
import styles from "../club/club.module.css";
import ReactPlayer from "react-player";
import appStyles from "../../../steuern2021/css/app.module.css";

interface IImageFileItem {
    num: number;
    fileName: string;
    image: any;
}

interface IPlayListItem {
    artist?: string;
    song?: string;
    filename?: string;
}

interface IState {
    playListItems: IPlayListItem[];
    currentSong: IPlayListItem;
}

const playList: IPlayListItem[] = [
    {
        artist: "Rihanna",
        song: "Lift me Up",
        filename: "artist_1/Rihanna_LiftMeUp.mp4"
    },
    {
        artist: "Rihanna",
        song: "Stay",
        filename: "artist_1/Rihanna_Stay.mp4"
    }
]

const emptyCurrentSong: IPlayListItem = null;

export class Test extends React.Component<{}, IState> {
    
    public state = {
        playListItems: playList,
        currentSong: emptyCurrentSong,
    }
    
    selectPlayItem = (playItem: IPlayListItem): void => {
        this.setState({currentSong: playItem})
    }
    
    playNextInList = (): void => {
        this.setState({currentSong: playList[1]});
    }
    
    render() {
        
        const { playListItems, currentSong } = this.state;
        
        console.log("Test.render()");
        
        const currentFilename: string = currentSong ? "/music/artist/" + currentSong.filename : "";
        console.log("currentFilename: " + currentFilename);
        
        
        return (
            <>
                <h3>Test Page</h3>
                
                
                <div>
                    <h3>Real Player Playlist</h3>
                    <h4>Playlist:</h4>
                    <div>
                        <ul>
                            {playListItems.map((item, idx) => {
                                return (
                                    <li key={"liArtist_" + idx} onClick={() => this.selectPlayItem(item)}>
                                        {item.artist} - {item.song} <br/>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <p>current Played song:</p>
                        {currentSong &&
                            <>
                                {currentSong.artist} - {currentSong.song}<br />
                                {currentFilename}
                            </>
                        }
                        
                    </div>
    
                    {currentSong &&
                        <ReactPlayer url={currentFilename} controls={true} className={appStyles.realPlayerBigFotoNavigator} onEnded={this.playNextInList} playing={true} />
                    }
                    
                </div>
            
            
            </>
        )
    }
}

