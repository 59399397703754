import React from 'react';
import natListStyles from "./natList.module.css";

const natInfoList = [
/* -- AAA --------------------------------------------------------------------------------------------------------- */
    { id: "afg", desc: "Afghanistan", region: "Asien"},
    { id: "egy", desc: "Ägypten", region: "Naher Osten"},
    { id: "alb", desc: "Albanien", region: "Europa"},
    { id: "alg", desc: "Algerien", region: "Afrika"},
    { id: "asa", desc: "Amerikanisch-Samoa", region: "Ozeanien"},
    { id: "isv", desc: "Amerikanische Jungferninseln", region: "Nordamerika"},
    { id: "and", desc: "Andorra", region: "Europa"},
    { id: "ang", desc: "Angola", region: "Afrika"},
    { id: "ant", desc: "Antigua und Barbuda", region: "Nordamerika"},
    { id: "geq", desc: "Äquatorialguinea", region: "Afrika"},
    { id: "arg", desc: "Argentinien", region: "Südamerika"},
    { id: "arm", desc: "Armenien", region: "Asien"},
    { id: "aru", desc: "Aruba", region: "Südamerika"},
    { id: "aze", desc: "Aserbaidschan", region: "Europa"},
    { id: "aus", desc: "Australien", region: "Ozeanien"},


    { id: "bra", desc: "Brasilien", region: "Südamerika"},
    { id: "bul", desc: "Bulgarien", region: "Europa"},

    { id: "ch", desc: "Schweiz", region: "Europa"},
    { id: "cro", desc: "Kroatien", region: "Europa"},
    { id: "cub", desc: "Kuba", region: "Südamerika"},
    { id: "crc", desc: "Costa Rica", region: "Südamerika"},

    { id: "d", desc: "Deutschland", region: "Europa"},
    { id: "dom", desc: "Domenikanische Republik", region: "Südamerika"},

    { id: "eth", desc: "Äthiopien", region: "Afrika"},
    { id: "eu", desc: "Europa", region: "Europa"},

    { id: "fra", desc: "Frankreich", region: "Europa"},


    { id: "hun", desc: "Ungarn", region: "Europa"},

    { id: "ita", desc: "Italien", region: "Europa"},

    { id: "jpn", desc: "Japan", region: "Asien"},

    { id: "lat", desc: "Lateinamerika / Latina"},
    { id: "lbn", desc: "Libanon", region: "Naher Osten"},
    { id: "let", desc: "Lettland", region: "Europa"},
    { id: "lit", desc: "Littauen", region: "Europa"},

    { id: "mar", desc: "Marokko", region: "Afrika"},
    { id: "mda", desc: "Moldau/Moldawien", region: "Europa"},
    { id: "mex", desc: "Mexico", region: "Nordamerika"},
    { id: "mos", desc: "Mosambik", region: "Afrika"},

    { id: "ned", desc: "Niederlande", region: "Europa"},

    { id: "par", desc: "Paraguay", region: "Südamerika"},
    { id: "per", desc: "Peru", region: "Südamerika"},
    { id: "pol", desc: "Polen", region: "Europa"},
    { id: "pur", desc: "Puerto Rico", region: "Südamerika"},

    { id: "qat", desc: "Katar", region: "Naher Osten"},

    { id: "rom", desc: "Rumänien", region: "Europa"},
    { id: "rus", desc: "Russland", region: "Europa"},

    { id: "svk", desc: "Slovakei", region: "Europa"},
    { id: "swe", desc: "Schweden", region: "Europa"},

    { id: "tbd", desc: "To Be Defined", region: "???"},
    { id: "tur", desc: "Türkei", region: "Europa"},

    { id: "ukr", desc: "Ukraine", region: "Europa"},
    { id: "usa", desc: "Amerika", region: "Nordamerika"},

    { id: "ven", desc: "Venezuela", region: "Südamerika"},
]

interface IProps {
    natString: string;
}

export class NatListComponent extends React.Component<IProps, {}> {

    render() {
        const { natString } = this.props;

        let natSplitList: string[] = [];
        if(natString) {
            natSplitList = natString.replace(/ /g,'').split("/");
        }


        return (
            <>
                {natSplitList.map((nat, idx) => {
                    const natLower = nat.toLowerCase();
                    let natDesc = nat;
                    const natIdx = natInfoList.findIndex(item =>  item.id === natLower);
                    if (natIdx >= 0) {
                        natDesc = natInfoList[natIdx].desc;
                    }
                    return (
                            <img key={"natImage_" + natLower + "_" + idx} src={"/flag/flag_" + natLower + "_small.png"} className={natListStyles.flagImg} title={natDesc} alt={natDesc} />
                        )
                    }
                )}
            </>
        )

    }

}
