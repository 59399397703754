import React from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {IMessageList} from "../../../../typeDef/messageList";
import {ClubDynListing} from "./ClubDynListing";
// import {ClubGirlDynListNormal} from "./ClubGirlDynListNormal";
import {IClubItem, IClubItems} from "../../typeDef/clubData";
import {MessageList} from "../../../../component/messageList/MessageList";
import {IGirlItem, IGirlItems} from "../../typeDef/girlData";
import {ClubGirlDynListNormal} from "./ClubGirlDynListNormal";
import {ClubGirlDynDetailInfo} from "./ClubGirDynDetailInfo";
import sl6ServicePath from "../../config/sl6Services.json";
import {IRootState, APPDATA_SETDATAFULL, APPDATA_UPDATEDATAITEM} from "../../../../redux";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import { appDataMapDispatchToProps } from "../../../../redux/appDataMapDispatchToProps";

interface IApiFeedback {
    state: string;
    error: string;
    msgList?: IMessageList
}

interface IState {
    apiClubListingFeedback: IApiFeedback,
    apiSingleClubDetails: {
        state: string;
        error: string;
        msgList?: IMessageList;
    }
    selectedClubId: number;
    selectedGirlId: number;
    girlSearchField: string;
    clubGirlsControlledIds?: number[];
    editGirl: IGirlItem;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adSl6Club: state.appData.sl6Club,
        adSl6Girl: state.appData.sl6Girl
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

const emptyMsgList: IMessageList = [];
const emptyClubGirlsControlledIds: number[] = [];
const emptyGirlItem: IGirlItem = null;

class ClubDynContainerComponent extends React.Component<Props, IState> {

    public state = {
        apiClubListingFeedback: {
            state: 'pending',
            error: '',
            msgList: emptyMsgList
        },
        apiSingleClubDetails: {
            state: 'pending',
            error: '',
            data: {},
            msgList: emptyMsgList
        },
        selectedClubId: 0,
        selectedGirlId: 0,
        girlSearchField: "",
        editGirl: emptyGirlItem,
        clubGirlsControlledIds: emptyClubGirlsControlledIds,
    }

    componentDidMount = () => {
        this.loadAllClubListing()
    }
    
    private callbackLoadAllClubListing = (serviceCall: ServiceCall) => {
        const apiClubListingFeedback = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        this.props.setDataFull("sl6Club", serviceCall.getResponseData());
        this.setState({apiClubListingFeedback});
    }
    loadAllClubListing = () => {
        const clubListingService = new ServiceCall(sl6ServicePath.clubListing);
        clubListingService.executePost({}, this.callbackLoadAllClubListing);
    }
    
    dispatchSetDataFull = (entity: string, data: any): void => {
        console.log("dispatchSetDataFull: " + entity);
        this.props.setDataFull(entity, data);
    }
    
    selectClub = (club: IClubItem) => {
        if (club &&
            club.clubId) {
            this.setState({selectedClubId: club.clubId, selectedGirlId: 0});
            this.loadSingleClub(club.clubId);
        } else {
            this.setState({selectedClubId: 0, selectedGirlId: 0});
        }
    }

    selectGirl = (girl: IGirlItem) => {
        if (girl === null) {
            this.setState({selectedGirlId: null, editGirl: null});
//            this.selectClub(this.state.selectedClub);
        } else {
            girl.wsMode = "";
            this.setState({selectedGirlId: girl.girlId, editGirl: girl});
        }
    }
    
    addNewGirl = (girl: IGirlItem) => {
        this.setState({selectedGirlId: girl.girlId, editGirl: girl})
    }
    
    loadSingleClub = (id: number) => {
        const singleClubService = new ServiceCall(sl6ServicePath.singleClubDetails);
        singleClubService.executePost({id: id}, this.callbackLoadSingleClubDetails);
    }
    
    private callbackLoadSingleClubDetails = (serviceCall: ServiceCall) => {
        const apiSingleClubDetails = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        const data: IClubItem = serviceCall.getResponseData();
        this.props.updateDataItem("sl6Club", data, "clubId")
        this.props.setDataFull("sl6Girl", data.girlList);
        this.setState({apiSingleClubDetails, selectedClubId: data.clubId, selectedGirlId: 0});
    }
    
    markGirlAsControlled = (girl: IGirlItem) => {
        const clubGirlsControlledIds = [];
        if (girl) {
            this.state.clubGirlsControlledIds.forEach((item) => {
                clubGirlsControlledIds.push(item);
            });
            clubGirlsControlledIds.push(girl.girlId);
        }
        this.setState({clubGirlsControlledIds});
    }

    openUrlInNewTab = (evt: any, url: string) => {
        evt.stopPropagation();
        const newWindow = window.open(url, 'ClubLink', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }
    
    changeHandlerEditGirl = (action: string, field: string, newValue: any): void => {
        console.log("ClubDynContainer.changeHandlerSelectedGirl:");
        console.log("action: " + action + " / " + field + " / " + newValue);
        const { selectedGirlId, editGirl } = this.state;
        if (action === 'reset') {
            if (editGirl.wsMode === 'A') {
                // spezial-Fall 'Add' --> wieder zurück auf Liste
                this.setState({selectedGirlId: 0})
            } else {
                // Normal-Fall 'Edit' --> einfach neu aus Store auslesen
                editGirl.wsMode = "";
                const sl6GirlFromStoreList = this.props.adSl6Girl;
                if (sl6GirlFromStoreList) {
                    const selectedGirl = sl6GirlFromStoreList.find((gItem: IGirlItem) => gItem.girlId === selectedGirlId);
                    this.selectGirl(selectedGirl);
                }
//                this.setState({selectedGirlId: selectedGirlBackup.girlId});
            }
        } else if (action === 'change') {
            const { editGirl } = this.state;
            // @ts-ignore
            editGirl[field] = newValue;
            this.setState({editGirl});
        }
    }
    
    girlSearchChangeHandler = (value: string): void => {
        this.setState({girlSearchField: value});
    }
    
    render() {
        const { selectedClubId, selectedGirlId, apiClubListingFeedback, clubGirlsControlledIds, editGirl, girlSearchField } = this.state;
        const storeClubList: IClubItems = [...this.props.adSl6Club];
        const storeGirlList: IGirlItems = [...this.props.adSl6Girl];
        
        console.log("ClubDynContainer.render()");
        console.log(storeGirlList);
        
        let clubDataList: IClubItems = [];
        if (storeClubList) {
            clubDataList = storeClubList.map((item: IClubItem) => {
                return item;
            });
        }
        let action = "ClubOverview";

        if (selectedGirlId > 0) {
            action = "GirlDetails";
        } else if (selectedClubId > 0) {
            action = "ClubDetails";
        }
        let selectedClubFromStore = clubDataList.find((club) => club.clubId === selectedClubId);
        if (!selectedClubFromStore) {
            action = "ClubOverview";
        }
        let selectedGirlFromStore: IGirlItem = null;
        if (selectedGirlId > 0) {
//            selectedGirlFromStore = selectedClubFromStore.girlList.find((girlItem: IGirlItem) => girlItem.girlId === selectedGirlId);
            selectedGirlFromStore = Object.assign({}, storeGirlList.find((girlItem: IGirlItem) => girlItem.girlId === selectedGirlId));
        }
        let showGirl: IGirlItem = selectedGirlFromStore;
        if (editGirl && (editGirl.wsMode === "A" || editGirl.wsMode === "U")) {
            showGirl = Object.assign({}, editGirl);
            console.log("showGirl is editGirl");
            console.log(showGirl);
        } else {
            console.log("showGirl is from Store");
            console.log(showGirl);
        }
        
        return (
            <>
{/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectClub(null)}>
                        all Clubs
                    </div>
                    {selectedClubId > 0 &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"} onClick={() => this.selectClub(selectedClubFromStore)}>
                                Club: {selectedClubFromStore.clubName} &nbsp; &nbsp;
                                    <img src={require("../../img/weblink_48px.png")} width={"32px"} height={"32px"} title={"Open Website of " + selectedClubFromStore.clubName} alt={"Link"}
                                         onClick={(e) => this.openUrlInNewTab(e, selectedClubFromStore.url)}/>
                            </div>
                        </>
                    }
                    {selectedGirlFromStore  &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"} onClick={() => this.selectGirl(selectedGirlFromStore)}>
                                Girl: {selectedGirlFromStore.girlName}
                            </div>
                        </>
                    }
                </div>

                <MessageList msgList={apiClubListingFeedback.msgList} />
                {/*
                -- List all Clubs ------------------------------------------------------------------------------------
*/}
                {action === 'ClubOverview' &&
                    <ClubDynListing clubDataList={clubDataList} fnSelectClub={this.selectClub}/>
                }
                {action === 'ClubDetails' &&
                    <ClubGirlDynListNormal selectedClub={selectedClubFromStore} fnSelectGirl={this.selectGirl} fnAddNewGirl={this.addNewGirl} clubGirlsControlledIds={clubGirlsControlledIds} filteredData={storeGirlList} fnMarkGirlAsControlled={this.markGirlAsControlled} girlSearchField={girlSearchField} fnGirlSearchChangeHandler={this.girlSearchChangeHandler}/>
                }
                {action === 'GirlDetails' &&
                    <ClubGirlDynDetailInfo fnSelectGirl={this.selectGirl} selectedGirlId={selectedGirlId} showGirl={showGirl} fnLoadSingleClub={this.loadSingleClub} fnChangeHandler={this.changeHandlerEditGirl} />
                }
            </>
        )
    }
}

export const ClubDynContainer = connect(mapStateToProps, appDataMapDispatchToProps)(ClubDynContainerComponent);
