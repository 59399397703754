import React from 'react';
import fileUploaderStyles from "./fileUploader.module.css";
import {FileUploaderModal} from "./FileUploaderModal";

const fileUploadIcon64 = require("../../img/fileuploader_64px.png");
const fileUploadIcon32 = require("../../img/fileuploader_32px.png");
const fileUploadIcon16 = require("../../img/fileuploader_16px.png");

interface IProps {
    iconSize?: string;
    previewSize?: string;
    uploadType: string;
    uploadId: any;
    onFileUploadCompleted?: () => void;
    phpExecuteProcedure: string;
}

interface IState {
    showModal: boolean;
}

export class FileUploader extends React.Component<IProps, IState> {
    
    public state = {
        showModal: false
    }
    
    private closeModal = () => {
        this.setState({showModal: false});
    }
    private showModal = () => {
        this.setState({showModal: true});
    }
    
    private onFileUploadCompleted = () => {
        console.log("FileUploader.onFileUploadCompleted()");
        this.props.onFileUploadCompleted();
    }
    
    
    
    render() {
        
        const {showModal} = this.state;
        let {iconSize, previewSize, uploadType, uploadId, phpExecuteProcedure} = this.props;
        
        /* Default-Values */
        let fileUploadIcon = fileUploadIcon64;
        if (iconSize === "s") {
            fileUploadIcon = fileUploadIcon16;
        } else if (iconSize === "m") {
            fileUploadIcon = fileUploadIcon32
        }
    
        
        return (
            <>
                <img src={fileUploadIcon} alt={"upload"} onClick={this.showModal} />
                {showModal &&
                    <>
                        <FileUploaderModal fnModalClose={this.closeModal} previewSize={previewSize} uploadType={uploadType} uploadId={uploadId} onFileUploadCompleted={this.onFileUploadCompleted} phpExecuteProcedure={phpExecuteProcedure} />
                    </>
                }
                
            </>
        )
    }

}
