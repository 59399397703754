import React from 'react';

import musicStyles from "../../css/music.module.css";
import {IArtistItem} from "../../typeDef/artistData";
import {InputText} from "../../../../component/input/InputText";
import {InputNumber} from "../../../../component/input/InputNumber";
import {ISongItem, ISongItems} from "../../typeDef/songData";
import {Button} from "react-bootstrap";
import {convertDateToDateString} from "../../../../sharedLib/dateLib";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import slmServicePath from "../../config/slmServices.json";
import {connect} from "react-redux";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {IRootState} from "../../../../redux";

const okSaveIcon = require("../../../../img/save_ok_32px.png");
const cancelIcon = require("../../../../img/cancel_32px.png");
const editIcon = require("../../../../img/edit_32px.png");
const isFavIcon = require("../../../../img/isFav_32px.png");
const isNoFavIcon = require("../../../../img/isNoFav_32px.png");

interface ICompProps {
    showArtist: IArtistItem;
    fnChangeHandler: (action: string, name?: string, newValue?: any) => void;
}
interface IState {
    editSong: ISongItem;
    showEditBtns: boolean;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adSlmSong: state.appData.slmSong,
        adSlmArtist: state.appData.slmArtist
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps & ICompProps;

const newSongItem: ISongItem = {
    artistId: 0,
    songId: 0,
    songTitle: "",
    createdDate: convertDateToDateString(new Date()),
    originYear: null,
    songDuration: "",
    fileName: "",
    isMamaFav: 0,
    wsMode: "A",
}
const emptySongItem: ISongItem = null;

class ArtistDetailsSongBlockComponent extends React.Component<Props, IState> {
    
    public state = {
        editSong: emptySongItem,
        showEditBtns: false,
    }
    
    private editSongChangeHandler = (action: string, fieldName: string, value: any): void => {
        let editSong = Object.assign({}, this.state.editSong);
        // @ts-ignore
        editSong[fieldName] = value;
        this.setState({editSong});
    }
    
    private addNewSongToEnter = (): void => {
        const {showArtist} = this.props;
        let newEntry: ISongItem = Object.assign({}, newSongItem);
        newEntry.artistId = showArtist.artistId;
        this.setState({editSong: newEntry});
    }
    private switchIsFav = (isEditable: boolean): void => {
        const newEditSong = Object.assign({}, this.state.editSong);
        if (isEditable) {
            newEditSong.isMamaFav = newEditSong.isMamaFav === 1 ? 0 : 1;
            this.setState({editSong: newEditSong});
        }
    }
    
    private cancelEditSong = (): void => {
        this.setState({editSong: null});
    }
    
    private callbackSaveEditSong = (sc: ServiceCall): void => {
        const data: IArtistItem = sc.getResponseData();
        this.setState({editSong: null});
        this.props.updateDataItem("slmArtist", data, "artistId");
        this.props.setDataFull("slmSong", data.songList);
    }
    private saveEditSong = (): void => {
        const {editSong} = this.state;
        const cs = new ServiceCall(slmServicePath.saveSongItem);
        cs.executePost(editSong, this.callbackSaveEditSong);
    
    }
    private showEditButtons = (newValue: boolean): void => {
        this.setState({showEditBtns: newValue})
    }
    
    private editEditSong = (song: ISongItem) => {
        console.log("editEditSong:");
        console.log(song);
        const editSong: ISongItem = Object.assign({}, song);
        editSong.wsMode = "U";
        this.setState({editSong});
    }
    
    render() {
        const { showArtist } = this.props;
        const {editSong, showEditBtns} = this.state;
        
        let songList: ISongItems = [];
        if (showArtist && showArtist.songList) {
            songList = showArtist.songList.map((si: ISongItem) => {
                return si;
            });
        }
        
        if (editSong && editSong.wsMode === "A") {
            songList.push(editSong);
        }
        
        return (
            <>
                <div>
                    <h4>Song(s)</h4>
                </div>
                <table width={"100%"}>
                    <colgroup>
                        <col width={"40%"} />
                        <col width={"8%"} />
                        <col width={"8%"} />
                        <col width={"20%"} />
                        <col width={"8%"} />
                        <col width={"8%"} />
                        <col width={"8%"} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>
                            Song Titel
                        </th>
                        <th>
                            Jahr
                        </th>
                        <th>
                            Created
                        </th>
                        <th>
                            Filename
                        </th>
                        <th>
                            Dauer
                        </th>
                        <th>
                            Fav
                        </th>
                        <th>
                            {!editSong &&
                                <>
                                    <Button size={"sm"} onClick={this.addNewSongToEnter}>+</Button>
                                    {showEditBtns === false &&
                                        <Button size={"sm"} onClick={() => this.showEditButtons(true)}>e</Button>
                                    }
                                    {showEditBtns === true &&
                                        <Button size={"sm"} onClick={() => this.showEditButtons(false)}>c</Button>
                                    }
                                </>
                            }
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {songList.map((song: ISongItem, songIdx) => {
                        const createdDateText: string = "" + (song.createdDate ? song.createdDate : "-");
                        let showSong = Object.assign({}, song);
                        if (editSong && song.songId === editSong.songId) {
                            showSong = Object.assign({}, editSong);
                        }
                        const isEditable: boolean = showSong.wsMode !== "";
                        const favIcon = showSong.isMamaFav === 1 ? isFavIcon : isNoFavIcon;
                        
                        return (
                            <tr key={"songTable_TR_" + songIdx}>
                                <td>
                                    <InputText name={"songTitle"} isEditable={isEditable}
                                               width={"100%"} value={showSong.songTitle}
                                               fnChangeHandler={this.editSongChangeHandler}/>
    
                                </td>
                                <td>
                                    <InputNumber name={"originYear"} isEditable={isEditable}
                                               width={"100%"} value={showSong.originYear}
                                               fnChangeHandler={this.editSongChangeHandler}/>
                                </td>
                                <td>
                                    <InputText name={"createdDateText"} isEditable={false}
                                               width={"100%"} value={createdDateText}
                                               fnChangeHandler={this.editSongChangeHandler}/>
                                </td>
                                <td>
                                    <InputText name={"fileName"} isEditable={isEditable}
                                               width={"100%"} value={showSong.fileName}
                                               fnChangeHandler={this.editSongChangeHandler}/>
                                </td>
                                <td>
                                    <InputText name={"songDuration"} isEditable={isEditable}
                                               width={"100%"} value={song.songDuration}
                                               fnChangeHandler={this.editSongChangeHandler}/>
                                </td>
                                <td>
                                    <img src={favIcon} alt={"Fav"} onClick={() => this.switchIsFav(isEditable)} />
                                </td>
                                <td>
                                    {editSong && editSong.songId === song.songId &&
                                        <>
                                            <img src={okSaveIcon} alt={"OK/Save"} onClick={this.saveEditSong} style={{cursor: "pointer"}} />
                                            <img src={cancelIcon} alt={"Cancel"} onClick={this.cancelEditSong} style={{cursor: "pointer"}} />
                                        </>
                                    }
                                    {!editSong && showEditBtns === true &&
                                        <img src={editIcon} alt={"Edit"} onClick={() => this.editEditSong(song)} style={{cursor: "pointer"}} />
                                    }
                                    
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </>
        )
    }
}

export const ArtistDetailsSongBlock = connect(mapStateToProps, appDataMapDispatchToProps)(ArtistDetailsSongBlockComponent);