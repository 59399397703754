export function convertDateToDateString (inDate: Date): string {
    let dateString: string = "";
    
    if (inDate !== null) {
        const workString: Date = new Date(inDate);
        const fullYear: number = workString.getFullYear();
        const monthNo: number = workString.getMonth() + 1;
        const dayNo: number = workString.getDate();
        
        dateString = "" + fullYear + "-" + (monthNo < 10 ? ("0" + monthNo) : monthNo) + "-" + (dayNo < 10 ? ("0" + dayNo) : dayNo);
    }
    return dateString;
}